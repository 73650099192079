<template>
  <div class="pa-3 ma-3">
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          v-model="busquedaItem.id_almacen"
          outlined
          hide-details
          dense
          label="Farmacia UMM"
          :loading="loadingAlmacenes"
          :items="almacenes"
          item-text="nombre"
          item-value="id_almacen"
          clearable
          @change="getMovimientos"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          v-model="busquedaItem.id_medicamento"
          outlined
          label="Medicamento"
          hide-details
          :items="medicamentos"
          :item-text="medicamentoNombre"
          item-value="id_medicamento"
          dense
          clearable
          @change="getMovimientos"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="9">
        Rango de Fechas: 
        <date-picker v-model="busquedaItem.rango" range valueType="format" format="DD-MM-YYYY" @change="getMovimientos"></date-picker>
      </v-col>
      <v-col cols="3">
        <v-btn
          block
          color="primary"
          text
          @click="descargarExcel"
        >
          <v-icon>
            mdi-download
          </v-icon>
          Descargar Movimientos
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :items="items" :headers="headers" :loading="loading">
          <template v-slot:[`item`]="{ item }">
            <tr>
              <td class="text-center">{{item.medicamento}}</td>
              <td class="text-center">{{item.stock_inicial}}</td>
              <td
                class="text-center"
                v-for="stock in item.stocks"
                :key="stock.fecha"
              >
                <v-icon color="green">mdi-arrow-right</v-icon> Entrada: {{stock.entradas}}
                <br>
                <v-icon color="red">mdi-arrow-left</v-icon> Salida: {{stock.salidas}}
              </td>
              <td class="text-center">{{item.stock_final}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>



<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapState } from 'vuex'
import axios from 'axios'


export default {
  components: {
    DatePicker
  },
  data: () => ({
    loading: false,
    items: [],
    headers: [],
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
      },
      monthBeforeYear: false,
    },
    busquedaItem: {
      rango: null,
      id_medicamento: null,
      id_almacen: null
    },
    loadingAlmacenes: false,
    almacenes: [],
    loadingMedicamentos: false,
    medicamentos: []
  }),
  mounted() {
    //this.getMovimientos();
    this.getMedicamentos();
    this.getAlmacenes();
  },
  computed: {
    ...mapState(['token']),
  },
  methods: {
    getItems(){
      this.getMedicamentos();
      this.getAlmacenes();
      this.getMovimientos();
    },
    medicamentoNombre: medicamento => `[${medicamento.codigo}] - ${medicamento.farmaco} ${medicamento.concentracion} - ${medicamento.presentacion}`,
    getMedicamentos(){
      this.loadingMedicamentos = true;
      axios
        .get('/Catalogos/ListarMedicamentos')
        .then(response => {
          this.medicamentos = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingMedicamentos = false;
        })
    },
    getAlmacenes() {
      this.loadingAlmacenes = true;
      axios.get('/Almacenes/ListarAlmacenes')
        .then(response => {
          this.almacenes = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAlmacenes = false;
        })
    },
    getMovimientos() {
      this.items = [];
      this.headers = [];

      if(this.busquedaItem.rango[0] == null || this.busquedaItem.rango[1] == null) return;


      const url = new URL(`http://localhost/Almacenes/ResumenMovimientos/${this.busquedaItem.rango[0]}/${this.busquedaItem.rango[1]}`);
      if(this.busquedaItem.id_almacen != null) url.searchParams.append("id_almacen", this.busquedaItem.id_almacen);
      if(this.busquedaItem.id_medicamento) url.searchParams.append("id_medicamento", this.busquedaItem.id_medicamento);
      var url_string = url.href.replace('http://localhost', '');


      this.loading = true;
      axios.get(url_string)
        .then(response => {
          this.items = response.data.stock;
          this.headers = response.data.headers;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    descargarExcel(){
      const url = new URL(`http://localhost/Almacenes/DescargarResumenMovimientos/${this.busquedaItem.rango[0]}/${this.busquedaItem.rango[1]}`);
      url.searchParams.append("jwt", this.token);

      if(this.busquedaItem.id_almacen != null) url.searchParams.append("id_almacen", this.busquedaItem.id_almacen);
      if(this.busquedaItem.id_medicamento) url.searchParams.append("id_medicamento", this.busquedaItem.id_medicamento);
      
      var url_string = url.href.replace('http://localhost', '/api');

      window.open(url_string, '_blank');
    },
  }
}
</script>
