<template>
  <v-container style="max-width: 1800px !important">
    <div class="pa-3 ma-3">
      <v-alert-dialog ref="alert"></v-alert-dialog>
    
      <v-row class="pa-0 ma-0">
        <v-col
          cols="12"
          class="pa-0 ma-0 text-center"
        >
          <div class="text-subtitle-1 font-weight-bold">
            <v-icon>
              mdi-account
            </v-icon>
            Datos Personales
          </div>
        </v-col>

        <v-col cols="12">
          <v-row class="pa-0 ma-0">
            <v-col cols="3" class="ma-0 pa-0 text-align-center">
              <v-avatar
                size="220"
              >
                <v-img
                  :src="editedItem.imagen_perfil"
                >
                </v-img>
              </v-avatar>
            </v-col>
            <v-col cols="9" class="ma-0 pa-0">
              <v-row class="pa-0 ma-0">
                <v-col cols="4">
                <div class="text-subtitle-2">Nombre:</div>
                <div>{{ editedItem.nombre }}</div>
              </v-col>
              <v-col cols="4">
                <div class="text-subtitle-2">Apellido Paterno:</div>
                <div>{{ editedItem.apellido_paterno }}</div>
              </v-col>
              <v-col cols="4">
                <div class="text-subtitle-2">Apellido Materno:</div>
                <div>{{ editedItem.apellido_materno }}</div>
              </v-col>
              <v-col cols="4">
                <div class="text-subtitle-2">Fecha de Nacimiento:</div>
                <div>{{ editedItem.fecha_nacimiento }}</div>
              </v-col>
              <v-col cols="4">
                <div class="text-subtitle-2">Teléfono de Contacto:</div>
                <div>{{ editedItem.telefono }}</div>
              </v-col>
              <v-col cols="4">
                <div class="text-subtitle-2">Email de Contacto:</div>
                <div>{{ editedItem.email }}</div>
              </v-col>
              <v-col cols="4">
                <div class="text-subtitle-2">Estado Civil:</div>
                <div>{{ editedItem.nombre_estado_civil }}</div>
              </v-col>
              <v-col cols="4">
                <div class="text-subtitle-2">CURP:</div>
                <div>{{ editedItem.curp_valor }}</div>
              </v-col>
              <v-col cols="4">
                <div class="text-subtitle-2">Puesto:</div>
                <div>{{ editedItem.nombre_puesto }}</div>
              </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="expedienteRequisitos.length > 0"
          cols="12"
          class="pa-0 ma-0 text-center"
        >
          <br>
          <div class="text-subtitle-1 font-weight-bold">
            <v-icon>
              mdi-file-document-outline
            </v-icon>
            Documentación
          </div>
        </v-col>
        <template
          v-for="expediente_requisito in expedienteRequisitos"
        >
          <v-col
            :key="expediente_requisito.codigo"
            cols="6"
          >
            <div
              v-if="editedItem[`msg_${expediente_requisito.codigo}`] != null"
              class="red--text text-caption"
            >
              Anomalia: {{editedItem[`msg_${expediente_requisito.codigo}`]}}
            </div>
            <template v-if="expediente_requisito.tipo_documento == 1">
              <v-file-input-plus
                :urlBase="urlBase"
                dense
                accept=".jpg,.jpeg,.png,.pdf"
                :max-size="5242880"
                outlined
                :label="`${expediente_requisito.nombre}`"
                v-model="editedItem[expediente_requisito.codigo]"
              ></v-file-input-plus>
              <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
            </template>
            <template v-else-if="expediente_requisito.tipo_documento == 2">
              <v-text-field
                v-model="editedItem[expediente_requisito.codigo]"
                outlined
                dense
                hide-details
                :label="`${expediente_requisito.nombre}`"
              ></v-text-field>
              <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
            </template>
            <template v-else-if="expediente_requisito.tipo_documento == 3">
              <v-text-field
                v-model="editedItem[expediente_requisito.codigo]"
                outlined
                dense
                hide-details
                :label="`${expediente_requisito.nombre}`"
              ></v-text-field>
              <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
            </template>
            <template v-else-if="expediente_requisito.tipo_documento == 4">
              <v-text-field
                v-model="editedItem[expediente_requisito.codigo]"
                outlined
                dense
                hide-details
                :label="`${expediente_requisito.nombre}`"
              ></v-text-field>
              <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
            </template>
          </v-col>
        </template>
        <v-col cols="12">
          <v-btn
            block
            color="primary"
            @click="saveInformation"
          >
            <v-icon>mdi-content-save</v-icon>
            Actualizar Mi Expediente
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import VFileInputPlus from '@/components/VFileInputPlus.vue';

export default {
  components: {
    VFileInputPlus
  },
  data: () => ({
    urlBase: '',
    editedItem: {
      id_empleado: '550e8400-e29b-41d4-a716-446655440000',
      id_puesto: '83497160-f077-4036-92dc-ef8ae15c632d',
      codigo_empleado: "EMP001",
      nombre_puesto: "Operador polivalente",
      nombre: "Sandra",
      apellido_paterno: "López",
      apellido_materno: "Ramírez",
      fecha_nacimiento: "1985-04-12",
      telefono: "55-1234-5678",
      email: "sandra.lopez@example.com",
      id_estado_civil: 'beb5253b-399b-4212-8dbb-20bfd95d5160',
      nombre_estado_civil: "Soltero/a",
      curp_valor: 'LOSR850412MDFLPN03',
      expediente_validado: true,
      expediente_validado_fecha: '01-nov-2024',
      expediente_validado_por: 'Carlos Fernández',
      imagen_perfil: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',

      acta_nacimiento: null,
      caratula_banco: null,
      carta_antecedentes_no_penales: null,
      carta_recomendacion_1: null,
      carta_recomendacion_2: null,
      cedula_profesional: null,
      cedula_profesional_kardex: null,
      cedula_profesional_kardex_salud: null,
      certificado_covid: null,
      certificado_estudios_cedula_profesional_titulo: null,
      certificado_estudios_kardex_legalizado: null,
      certificado_medico: null,
      certificado_ultimo_grado: null,
      comprobante_domicilio: null,
      constancia_situacion_fiscal: null,
      curp: null,
      curriculum_vitae: null,
      fotografia_credencial: null,
      identificacion_oficial: null,
      kardex_legalizado: null,
      licencia_conducir: null,
      licencia_conducir_equipo_pesado: null,
      nombre_contacto_emergencia: null,
      nss_imss: null,
      solicitud_empleo: null,
      telefono_contacto_emergencia: null,
      titulo_cedula_posgrado: null,
      titulo_profesional: null,
      titulo_profesional_carta_pasante: null,

      msg_acta_nacimiento: null,
      msg_caratula_banco: null,
      msg_carta_antecedentes_no_penales: null,
      msg_carta_recomendacion_1: null,
      msg_carta_recomendacion_2: null,
      msg_cedula_profesional: null,
      msg_cedula_profesional_kardex: null,
      msg_cedula_profesional_kardex_salud: null,
      msg_certificado_covid: null,
      msg_certificado_estudios_cedula_profesional_titulo: null,
      msg_certificado_estudios_kardex_legalizado: null,
      msg_certificado_medico: null,
      msg_certificado_ultimo_grado: null,
      msg_comprobante_domicilio: null,
      msg_constancia_situacion_fiscal: null,
      msg_curp: null,
      msg_curriculum_vitae: null,
      msg_fotografia_credencial: null,
      msg_identificacion_oficial: null,
      msg_kardex_legalizado: null,
      msg_licencia_conducir: null,
      msg_licencia_conducir_equipo_pesado: null,
      msg_nombre_contacto_emergencia: null,
      msg_nss_imss: null,
      msg_solicitud_empleo: null,
      msg_telefono_contacto_emergencia: null,
      msg_titulo_cedula_posgrado: null,
      msg_titulo_profesional: null,
      msg_titulo_profesional_carta_pasante: null,
    },
    defaultEditedItem: {
      id_empleado: null,
      id_puesto: null,
      codigo_empleado: null,
      nombre_puesto: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      telefono: null,
      email: null,
      id_estado_civil: null,
      nombre_estado_civil: null,
      curp_valor: null,
      expediente_validado: false,
      expediente_validado_fecha: null,
      expediente_validado_por: null,
      imagen_perfil: null,
      id_usuario: null,
      crear_usuario: false,

      acta_nacimiento: null,
      caratula_banco: null,
      carta_antecedentes_no_penales: null,
      carta_recomendacion_1: null,
      carta_recomendacion_2: null,
      cedula_profesional: null,
      cedula_profesional_kardex: null,
      cedula_profesional_kardex_salud: null,
      certificado_covid: null,
      certificado_estudios_cedula_profesional_titulo: null,
      certificado_estudios_kardex_legalizado: null,
      certificado_medico: null,
      certificado_ultimo_grado: null,
      comprobante_domicilio: null,
      constancia_situacion_fiscal: null,
      curp: null,
      curriculum_vitae: null,
      fotografia_credencial: null,
      identificacion_oficial: null,
      kardex_legalizado: null,
      licencia_conducir: null,
      licencia_conducir_equipo_pesado: null,
      nombre_contacto_emergencia: null,
      nss_imss: null,
      solicitud_empleo: null,
      telefono_contacto_emergencia: null,
      titulo_cedula_posgrado: null,
      titulo_profesional: null,
      titulo_profesional_carta_pasante: null,

      msg_acta_nacimiento: null,
      msg_caratula_banco: null,
      msg_carta_antecedentes_no_penales: null,
      msg_carta_recomendacion_1: null,
      msg_carta_recomendacion_2: null,
      msg_cedula_profesional: null,
      msg_cedula_profesional_kardex: null,
      msg_cedula_profesional_kardex_salud: null,
      msg_certificado_covid: null,
      msg_certificado_estudios_cedula_profesional_titulo: null,
      msg_certificado_estudios_kardex_legalizado: null,
      msg_certificado_medico: null,
      msg_certificado_ultimo_grado: null,
      msg_comprobante_domicilio: null,
      msg_constancia_situacion_fiscal: null,
      msg_curp: null,
      msg_curriculum_vitae: null,
      msg_fotografia_credencial: null,
      msg_identificacion_oficial: null,
      msg_kardex_legalizado: null,
      msg_licencia_conducir: null,
      msg_licencia_conducir_equipo_pesado: null,
      msg_nombre_contacto_emergencia: null,
      msg_nss_imss: null,
      msg_solicitud_empleo: null,
      msg_telefono_contacto_emergencia: null,
      msg_titulo_cedula_posgrado: null,
      msg_titulo_profesional: null,
      msg_titulo_profesional_carta_pasante: null,

      error_acta_nacimiento: null,
      error_caratula_banco: null,
      error_carta_antecedentes_no_penales: null,
      error_carta_recomendacion_1: null,
      error_carta_recomendacion_2: null,
      error_cedula_profesional: null,
      error_cedula_profesional_kardex: null,
      error_cedula_profesional_kardex_salud: null,
      error_certificado_covid: null,
      error_certificado_estudios_cedula_profesional_titulo: null,
      error_certificado_estudios_kardex_legalizado: null,
      error_certificado_medico: null,
      error_certificado_ultimo_grado: null,
      error_comprobante_domicilio: null,
      error_constancia_situacion_fiscal: null,
      error_curp: null,
      error_curriculum_vitae: null,
      error_fotografia_credencial: null,
      error_identificacion_oficial: null,
      error_kardex_legalizado: null,
      error_licencia_conducir: null,
      error_licencia_conducir_equipo_pesado: null,
      error_nombre_contacto_emergencia: null,
      error_nss_imss: null,
      error_solicitud_empleo: null,
      error_telefono_contacto_emergencia: null,
      error_titulo_cedula_posgrado: null,
      error_titulo_profesional: null,
      error_titulo_profesional_carta_pasante: null,
    },
    puestos: [],
    loadingPuestos: false
  }),
  computed: {
    expedienteRequisitos(){
      if(typeof this.editedItem.id_puesto == 'undefined'  || this.editedItem.id_puesto == null) return [];
      var idx = this.puestos.findIndex(x => x.id_puesto == this.editedItem.id_puesto);
      if(idx >= 0){
        return this.puestos[idx].expediente_requisitos; //.filter(x => x.id_expediente_requisito != '58e817f0-9fd2-444b-975f-981f128c2366');
      }
      else return [];
    },
  },
  mounted(){
    this.getPuestos();
  },
  methods: {
    getPuestos(){
      this.loadingPuestos = true;
      axios
      .get('/RecursosHumanos/ListarPuestos')
      .then(response => {
        this.puestos = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingPuestos = false;
      })
    },
    validateInformation(){
      for (let i = 0; i <= this.expedienteRequisitos.length; i++) {
        var requisito = this.expedienteRequisitos[i];
        if(requisito.requerido == true && this.editedItem[requisito.codigo] == null){
          this.$refs.alert.setMessage("", `El ${requisito.nombre.replace(' *', '')} es Requerido.`);
          this.$refs.alert.open();
          return false;
        }
      }
      return true;
    },
    saveInformation(){
      if(!this.validateInformation()) return;
    }
  }
};
</script>