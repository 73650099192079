<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="2" class="pa-0 ma-0">
      <v-tabs
        v-model="tab"
        vertical
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
           href="#clasificacion_vehiculos"
          @click="actualizarItems('clasificacion_vehiculos')"
        >
          Clasificación <br>de Vehículos
        </v-tab>

        <v-tab
           href="#estatus_vehiculos"
          @click="actualizarItems('estatus_vehiculos')"
        >
          Estatus <br>de Vehículos
        </v-tab>

        <v-tab
           href="#tipo_uso_vehiculo"
          @click="actualizarItems('tipo_uso_vehiculo')"
        >
          Tipo de Uso <br>de Vehículos
        </v-tab>
      </v-tabs>
    </v-col>

    <v-col cols="10" class="pa-0 ma-0">      
      <v-tabs-items v-model="tab">
        <v-tab-item value="clasificacion_vehiculos">
          <ClasificacionVehiculos
            ref="clasificacion_vehiculos"
          ></ClasificacionVehiculos>
        </v-tab-item>

        <v-tab-item value="estatus_vehiculos">
          <EstatusVehiculo
            ref="estatus_vehiculos"
          ></EstatusVehiculo>
        </v-tab-item>

        <v-tab-item value="tipo_uso_vehiculo">
          <TipoUsoVehiculo
            ref="tipo_uso_vehiculo"
          ></TipoUsoVehiculo>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import ClasificacionVehiculos from './Catalogos/ClasificacionVehiculos.vue'
import EstatusVehiculo from './Catalogos/EstatusVehiculo.vue'
import TipoUsoVehiculo from './Catalogos/TipoUsoVehiculo.vue'
//import { mapState } from 'vuex'

export default {
  computed: {
    //...mapState(['ids_vistas'])
  },
  components:{
    ClasificacionVehiculos,
    EstatusVehiculo,
    TipoUsoVehiculo
  },
  data: () => ({
    tab: 'clasificacion_vehiculos'
  }),
  methods: {
    /*
    mostrarVista(id_vista){
			if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
			return (this.ids_vistas.indexOf(id_vista) >= 0);
		},
    */
    getItems(){
      this.actualizarItems(this.tab);
    },
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    }
  }
}
</script>