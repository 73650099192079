<template>
  <v-tooltip bottom color="black" class="white--text">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        :color="color"
        class="cursor-pointer"
      >
        {{ icon }}
      </v-icon>
    </template>
    <span class="tooltip-content">{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'IconWithTooltip',
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    tooltipText: {
      type: String,
      default: 'Tooltip text',
    },
  },
}
</script>