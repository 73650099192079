<template>
  <div>
    <v-btn
      block
      color="primary"
      text
      @click="openDialog"
    >
      <v-icon>
        mdi-download
      </v-icon>
      Descargar Concentrado de Pacientes
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          Descargar Concentrado de Pacientes
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              Rango de Fechas:
              <date-picker v-model="busquedaItem.rango" range valueType="format" format="DD-MM-YYYY" ></date-picker>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="busquedaItem.id_unidad_medica"
                :items="unidades_medicas"
                outlined
                label="Unidad Medica (Opcional)"
                :item-text="nombreUnidad"
                item-value="id_unidad_medica"
                :loading="loadingUnidadesMedicas"
                dense
                hide-details
                clearable
                :disabled="loadingUnidadesMedicas"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="busquedaItem.id_proyecto"
                :items="proyectos"
                outlined
                label="Proyecto (Opcional)"
                :item-text="nombreProyecto"
                item-value="id_proyecto"
                :loading="loadingProyectos"
                :disabled="loadingProyectos"
                dense
                hide-details
                clearable
              >
              </v-autocomplete>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12"
            >
              <v-btn
                  block
                  color="primary"
                  text
                  @click="descargarExcel"
                  :disabled="busquedaItem.rango == null || busquedaItem.rango.length != 2"
                >
                  <v-icon>
                    mdi-download
                  </v-icon>
                  Descargar Concentrado de Pacientes
                </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';


export default {
  components: {
    DatePicker,
  },
  data: () => ({
    dialog: false,
    busquedaItem: {
      rango: null,
      id_unidad_medica: null,
      id_proyecto: null,
    },

    defaultBusquedaItem: {
      rango: null,
      id_unidad_medica: null,
      id_proyecto: null,
    },

    proyectos: [],
    loadingProyectos: false,

    unidades_medicas: [],
    loadingUnidadesMedicas: false,

  }),
  mounted() {
    this.getUnidadesMedicas();
    this.getProyectos();
  },
  computed: {
    ...mapState(['token']),
  },
  methods: {
    nombreUnidad: item => `[${item.codigo}] - ${item.nombre}`,
    nombreProyecto: item => `[${item.codigo}] - ${item.nombre}`,
    getUnidadesMedicas(){
      this.loadingUnidadesMedicas = true;
      axios
      .get('/Catalogos/ListarUnidadesMedicas')
      .then(response => {
        this.unidades_medicas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingUnidadesMedicas = false;
      })
    },
    getProyectos(){
      this.loadingProyectos = true;
      axios
        .get('/Catalogos/ListarProyectos?solo_activos=false')
        .then(response => {
          this.proyectos = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingProyectos = false;
        })
    },
    descargarExcel(){
      const url = new URL(`http://localhost/Incidencias/DescargarConcentradoPacientes/${this.busquedaItem.rango[0]}/${this.busquedaItem.rango[1]}`);
      url.searchParams.append("jwt", this.token);

      if(this.busquedaItem.id_proyecto != null) url.searchParams.append("id_proyecto", this.busquedaItem.id_proyecto);
      if(this.busquedaItem.id_unidad_medica) url.searchParams.append("id_unidad_medica", this.busquedaItem.id_unidad_medica);
      
      var url_string = url.href.replace('http://localhost', '/api');

      window.open(url_string, '_blank');
    },
    openDialog(){
      this.dialog = true;
    },
    closeDialog(){
      this.busquedaItem = Object.assign({}, this.defaultBusquedaItem);
      this.dialog = false;
    }
  }
}
</script>
