<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    hide-default-header
    hide-default-footer
    class="fixed-first-column-data table-bordered"
  >
    <!-- Slot for customizing the header -->
    <template v-slot:header>
      <thead class="v-data-table-header">
        <tr>
          <th
            :colspan="8"
            :class="['header-cell', 'border', 'border-1']"
            :style="{ backgroundColor: '#FF5733', color: '#FFFFFF' }"
          >
            {{supertitle}}
          </th>
        </tr>
        <tr>
          <th
            :colspan="8"
            :class="['header-cell', 'border', 'border-1']"
            :style="{ backgroundColor: '#FDB73E', color: '#000000' }"
          >
            {{title}}
          </th>
        </tr>
        <tr class="fixed-first-header">
          <th
            v-for="(item, idx) in groups"
            :key="'group-' + idx"
            :colspan="item.colspan"
            :rowspan="item.rowspan"
            :class="['header-cell', 'border', 'border-1']"
            :style="{ backgroundColor: item.color, color: item.textColor }"
          >
            {{ item.text }}
          </th>
        </tr>
        <tr>
          <template v-for="(header, idx) in headers">
            <th
              v-if="!header.hide"
              :key="'header' + idx"
              class="header-cell border border-1"
              :style="{
                backgroundColor: header.color,
                color: header.textColor,
              }"
            >
              {{ header.text }}
            </th>
          </template>
        </tr>
      </thead>
    </template>

    <!-- Slot for customizing the rows -->
    <template v-slot:item="{ item }">
      <tr>
        <td
          v-for="(header, index) in headers"
          :key="'item' + index"
          :class="['data-cell', 'border', 'border-1']"
          :style="getStyle(header, item, index)"
        >
          {{ (index == 4 || index == 7)  ? (`${item[header.value].toFixed(2)}%`) : item[header.value] }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>

export default {
  methods: {
    getStyle(header, item, index) {
      let backgroundColor = undefined;
      let color = undefined;

      if (!header.colorJustTitle) {
        backgroundColor = header.color;
        color = header.textColor;
      } else {
        const idxHeader = this.headers.indexOf(header);
        const idx = this.items.indexOf(item);

        if (idx >= 0 && this.rows != null && this.rows.length > idx && idxHeader == 1) {
          backgroundColor = this.rows[idx].color;
          color = this.rows[idx].textColor;
        }
      }

      // Apply red background and text color if the value is negative in columns 3 or 6
      if ((index === 3 || index === 6) && parseInt(item[header.value]) < 0) {
        backgroundColor = '#FFC7CE';
        color = '#9C0006';
      }

      if ((index === 4 || index === 7)) {
        const value = parseFloat(item[header.value]);
        backgroundColor = this.getGradientColor(value);
        color = this.getTextColor(backgroundColor);
      }

      return {
        backgroundColor: backgroundColor,
        color: color,
      };
    },
    getGradientColor(value) {
      const red = { r: 248, g: 105, b: 107 };
      const yellow = { r: 255, g: 235, b: 132 };
      const green = { r: 99, g: 190, b: 123 };

      let startColor, endColor, ratio;

      if (value <= 50) {
        // Between red and yellow
        startColor = red;
        endColor = yellow;
        ratio = value / 50;
      } else if (value <= 100) {
        // Between yellow and green
        startColor = yellow;
        endColor = green;
        ratio = (value - 50) / 50;
      } else {
        // If value is greater than 100, keep it green
        return `rgb(${green.r}, ${green.g}, ${green.b})`;
      }

      const r = Math.round(startColor.r + (endColor.r - startColor.r) * ratio);
      const g = Math.round(startColor.g + (endColor.g - startColor.g) * ratio);
      const b = Math.round(startColor.b + (endColor.b - startColor.b) * ratio);

      return `rgb(${r}, ${g}, ${b})`;
    },
    getTextColor(backgroundColor) {
      // Extract RGB values
      const rgb = backgroundColor.match(/\d+/g).map(Number);
      const [r, g, b] = rgb;

      // Calculate brightness
      const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

      // Return black or white text color
      return brightness > 0.5 ? '#000000' : '#FFFFFF';
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    supertitle: {
      type: String,
      default: "",
    },
    groups: {
      type: Array,
      default: () => ([])
    },
    headers: {
      type: Array,
      default: () => ([])
    },
    items: {
      type: Array,
      default: () => ([])
    },
    rows: {
      type: Array,
      default: () => ([])
    },
  }
}
</script>

<style scoped>
.header-cell,
.data-cell {
  padding: 16px; /* Espaciado interno */
  margin: 0; /* Espaciado externo */
  text-align: center !important; /* Centrar el texto */
  border: 1px solid rgba(0, 0, 0, 0.12) !important; /* Línea de separación */
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.12); /* Borde de la tabla */
}

.fixed-first-column .v-data-table__wrapper {
  overflow-x: auto;
}

.fixed-first-column-data table td:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  border-right: 1px solid #e0e0e0;
}

.fixed-first-header th:first-child {
  position: sticky;
  left: 0;
  background: white;
  z-index: 2; /* Aumentar el z-index para asegurarse de que esté sobre las celdas de datos */
  border-right: 1px solid #e0e0e0;
}
</style>
