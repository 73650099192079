<template>
  <div>
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-row>
      <v-col cols="6">
        <span class="font-weight-bold">Antecedentes heredofamiliares</span>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Hipertensión:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.familiar_hipertension"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                  label="Si"
                  :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.familiar_hipertension_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Diabetes:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.familiar_diabetes"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.familiar_diabetes_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Cáncer:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.familiar_cancer"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.familiar_cancer_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Otros:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              v-model="editedItem.familiar_otros"
              outlined
              hide-details
              dense
              rows="1"
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>
        <br>
        <br>
        <span class="font-weight-bold">Antecedentes personales patológicos</span>
        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Alergias:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_alergias"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_alergias_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Cáncer:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_cancer"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_cancer_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Convulciones:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_convulciones"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_convulciones_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Diabetes:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_diabetes"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_diabetes_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Hipertensión:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_hipertension"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_hipertension_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Hospitalizaciones Previas:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_hospitalizacion"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_hospitalizacion_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Infectocontagiosas:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_infecciones"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_infecciones_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Neurológicas:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_neurologicas"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_neurologicas_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Quirúrgicos:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_quirurgicos"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_quirurgicos_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Traumatológicos:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_traumatologicos"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_traumatologicos_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Otros:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              v-model="editedItem.personal_otros"
              outlined
              hide-details
              dense
              rows="1"
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="6">
        <span class="font-weight-bold">Antecedentes personales no patológicos</span>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Consumo de alcohol:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_alcohol"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Tabaquísmo/Biomasa:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_tabaquismo"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>
        
        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Toxicomanias:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_toxicomanias"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Zoonosis:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_zoonosis"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Número de comidas al día:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_comidas_dia"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Tipo de Habitación:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_tipo_habitacion"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Inmunizaciones:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_inmunizaciones"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Lugar de disposición de excretas:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_lugar_excreciones"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Escolaridad:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_escolaridad"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Tipo de sangre y RH:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_tipo_sangre"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="6">
        <span class="font-weight-bold">Antecendentes Gineco-obstétricos:</span>
        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Fecha de última menstruación:
          </v-col>
          <v-col cols="10" class="ma-0 pa-0">
            <!--
            <v-textarea
              :rows="1"
              auto-grow
              
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
            -->
            <v-datetime-picker
              v-model="editedItem.antecedentes_ultima_menstruacion"
              @input="calcularFechaParto"
            >
            </v-datetime-picker>
          </v-col>

          <v-col cols="2" class="pa-0 ma-0">
            Fecha de probable parto:
          </v-col>
          <v-col cols="10" class="ma-0 pa-0">
            <v-textarea
              readonly
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_probable_parto"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Inicio de vida sexual activa:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0 pr-2">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_inicio_vida_sexual"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>

          <v-col cols="2" class="pa-0 ma-0 pl-2">
            Menarca:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_menarca"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Intervalo entre menstruaciones:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0 pr-2">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_intervalo_mestruaciones"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>

          <v-col cols="2" class="pa-0 ma-0 pl-2">
            Duración del flujo menstrual:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_duracion_flujo_menstrual"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Presencia de otras secreciones:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0 pr-2">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_otras_secreciones"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>

          <v-col cols="2" class="pa-0 ma-0 pl-2">
            Cantidad y caracteristicas del flujo menstrual:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_flujo_menstrual"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Enfermedades de transmisión sexual:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0 pr-2">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_enfermedades_sexuales"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>

          <v-col cols="2" class="pa-0 ma-0 pl-2">
            Última citología cervical:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_ultima_citologia_cervical"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Número de Parejas Sexuales:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0 pr-2">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_num_parejas_sexuales"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>

          <v-col cols="2" class="pa-0 ma-0 pl-2">
            Uso de métodos anticonceptivos:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_metodos_anticonceptivos"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Gestas:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0 pr-2">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_gestas"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>

          <v-col cols="2" class="pa-0 ma-0 pl-2">
            Partos:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_partos"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Cesáreas:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0 pr-2">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_cesareas"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>

          <v-col cols="2" class="pa-0 ma-0 pl-2">
            Abortos:
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_abortos"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Datos de alarma obstétricos
          </v-col>
          <v-col cols="10" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_alarma_obstetricos"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="2" class="pa-0 ma-0">
            Antecedentes Perinatales
          </v-col>
          <v-col cols="10" clfass="ma-0 pa-0">
            <v-radio-group
              v-model="editedItem.antecedentes_perinatales"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="6">
        <span class="font-weight-bold">Exploración Física</span>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Piel
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_piel"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_piel_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Cabeza y cuello
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_cabeza"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_cabeza_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Tórax
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_torax"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_torax_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Abdomen
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_abdomen"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_abdomen_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Columna
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_columna"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_columna_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Extremidades Superiores
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_extremidades_superiores"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_extremidades_superiores_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Extremidades Inferiores
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_extremidades_inferiores"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_extremidades_inferiores_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Genitales
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_genitales"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_genitales_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Sistema Nervioso
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_sistema_nervioso"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_sistema_nervioso_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Otros Agregados:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              v-model="editedItem.exploracion_otros"
              outlined
              hide-details
              dense
              rows="1"
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import VAlertDialog from '@/components/VAlertDialog.vue'
import VDatetimePicker from '@/components/VDatetimePickerOld.vue';
import moment from 'moment';

export default {
  components: {
    VAlertDialog,
    VDatetimePicker,
  },
  data: () => ({
    dialog: false,
    editedItem: {
      familiar_hipertension: null,
      familiar_hipertension_nota: null,
      familiar_diabetes: null,
      familiar_diabetes_nota: null,
      familiar_cancer: null,
      familiar_cancer_nota: null,
      familiar_otros: null,
      personal_alergias: null,
      personal_alergias_nota: null,
      personal_cancer: null,
      personal_cancer_nota: null,
      personal_convulciones: null,
      personal_convulciones_nota: null,
      personal_diabetes: null,
      personal_diabetes_nota: null,
      personal_hipertension: null,
      personal_hipertension_nota: null,
      personal_hospitalizacion: null,
      personal_hospitalizacion_nota: null,
      personal_infecciones: null,
      personal_infecciones_nota: null,
      personal_neurologicas: null,
      personal_neurologicas_nota: null,
      personal_quirurgicos: null,
      personal_quirurgicos_nota: null,
      personal_traumatologicos: null,
      personal_traumatologicos_nota: null,
      personal_otros: null,
      antecedentes_alcohol: null,
      antecedentes_tabaquismo: null,
      antecedentes_toxicomanias: null,
      antecedentes_zoonosis: null,
      antecedentes_comidas_dia: null,
      antecedentes_tipo_habitacion: null,
      antecedentes_inmunizaciones: null,
      antecedentes_lugar_excreciones: null,
      antecedentes_escolaridad: null,
      antecedentes_tipo_sangre: null,
      antecedentes_ultima_menstruacion: null,
      antecedentes_probable_parto: null,
      antecedentes_inicio_vida_sexual: null,
      antecedentes_menarca: null,
      antecedentes_intervalo_mestruaciones: null,
      antecedentes_duracion_flujo_menstrual: null,
      antecedentes_otras_secreciones: null,
      antecedentes_flujo_menstrual: null,
      antecedentes_enfermedades_sexuales: null,
      antecedentes_ultima_citologia_cervical: null,
      antecedentes_num_parejas_sexuales: null,
      antecedentes_metodos_anticonceptivos: null,
      antecedentes_gestas: null,
      antecedentes_partos: null,
      antecedentes_cesareas: null,
      antecedentes_abortos: null,
      antecedentes_alarma_obstetricos: null,
      antecedentes_perinatales: null,
      exploracion_piel: null,
      exploracion_piel_nota: null,
      exploracion_cabeza: null,
      exploracion_cabeza_nota: null,
      exploracion_torax: null,
      exploracion_torax_nota: null,
      exploracion_abdomen: null,
      exploracion_abdomen_nota: null,
      exploracion_columna: null,
      exploracion_columna_nota: null,
      exploracion_extremidades_superiores: null,
      exploracion_extremidades_superiores_nota: null,
      exploracion_extremidades_inferiores: null,
      exploracion_extremidades_inferiores_nota: null,
      exploracion_genitales: null,
      exploracion_genitales_nota: null,
      exploracion_sistema_nervioso: null,
      exploracion_sistema_nervioso_nota: null,
      exploracion_otros: null
    },
    defaultEditedItem: {
      familiar_hipertension: null,
      familiar_hipertension_nota: null,
      familiar_diabetes: null,
      familiar_diabetes_nota: null,
      familiar_cancer: null,
      familiar_cancer_nota: null,
      familiar_otros: null,
      personal_alergias: null,
      personal_alergias_nota: null,
      personal_cancer: null,
      personal_cancer_nota: null,
      personal_convulciones: null,
      personal_convulciones_nota: null,
      personal_diabetes: null,
      personal_diabetes_nota: null,
      personal_hipertension: null,
      personal_hipertension_nota: null,
      personal_hospitalizacion: null,
      personal_hospitalizacion_nota: null,
      personal_infecciones: null,
      personal_infecciones_nota: null,
      personal_neurologicas: null,
      personal_neurologicas_nota: null,
      personal_quirurgicos: null,
      personal_quirurgicos_nota: null,
      personal_traumatologicos: null,
      personal_traumatologicos_nota: null,
      personal_otros: null,
      antecedentes_alcohol: null,
      antecedentes_tabaquismo: null,
      antecedentes_toxicomanias: null,
      antecedentes_zoonosis: null,
      antecedentes_comidas_dia: null,
      antecedentes_tipo_habitacion: null,
      antecedentes_inmunizaciones: null,
      antecedentes_lugar_excreciones: null,
      antecedentes_escolaridad: null,
      antecedentes_tipo_sangre: null,
      antecedentes_ultima_menstruacion: null,
      antecedentes_probable_parto: null,
      antecedentes_inicio_vida_sexual: null,
      antecedentes_menarca: null,
      antecedentes_intervalo_mestruaciones: null,
      antecedentes_duracion_flujo_menstrual: null,
      antecedentes_otras_secreciones: null,
      antecedentes_flujo_menstrual: null,
      antecedentes_enfermedades_sexuales: null,
      antecedentes_ultima_citologia_cervical: null,
      antecedentes_num_parejas_sexuales: null,
      antecedentes_metodos_anticonceptivos: null,
      antecedentes_gestas: null,
      antecedentes_partos: null,
      antecedentes_cesareas: null,
      antecedentes_abortos: null,
      antecedentes_alarma_obstetricos: null,
      antecedentes_perinatales: null,
      exploracion_piel: null,
      exploracion_piel_nota: null,
      exploracion_cabeza: null,
      exploracion_cabeza_nota: null,
      exploracion_torax: null,
      exploracion_torax_nota: null,
      exploracion_abdomen: null,
      exploracion_abdomen_nota: null,
      exploracion_columna: null,
      exploracion_columna_nota: null,
      exploracion_extremidades_superiores: null,
      exploracion_extremidades_superiores_nota: null,
      exploracion_extremidades_inferiores: null,
      exploracion_extremidades_inferiores_nota: null,
      exploracion_genitales: null,
      exploracion_genitales_nota: null,
      exploracion_sistema_nervioso: null,
      exploracion_sistema_nervioso_nota: null,
      exploracion_otros: null
    },
    validaciones: [
      { nombre: 'Antecedentes heredofamiliares - Hipertensión', campo: 'familiar_hipertension', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes heredofamiliares - Diabetes', campo: 'familiar_diabetes', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes heredofamiliares - Cáncer', campo: 'familiar_cancer', tipo: 'boolean', nota: true, valor_nota: true },

      { nombre: 'Antecedentes personales patológicos - Alergias', campo: 'personal_alergias', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Cáncer', campo: 'personal_cancer', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Convulciones', campo: 'personal_convulciones', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Diabetes', campo: 'personal_diabetes', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Hipertensión', campo: 'personal_hipertension', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Hospitalizaciones Previas', campo: 'personal_hospitalizacion', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Infectocontagiosas', campo: 'personal_infecciones', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Neurológicas', campo: 'personal_neurologicas', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Quirúrgicos', campo: 'personal_quirurgicos', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Traumatológicos', campo: 'personal_traumatologicos', tipo: 'boolean', nota: true, valor_nota: true },

      { nombre: 'Antecedentes personales no patológicos - Consumo de alcohol', campo: 'antecedentes_alcohol', tipo: 'string' },
      { nombre: 'Antecedentes personales no patológicos - Tabaquísmo/Biomasa', campo: 'antecedentes_tabaquismo', tipo: 'string' },
      { nombre: 'Antecedentes personales no patológicos - Toxicomanias', campo: 'antecedentes_toxicomanias', tipo: 'string' },
      { nombre: 'Antecedentes personales no patológicos - Zoonosis', campo: 'antecedentes_zoonosis', tipo: 'string' },
      { nombre: 'Antecedentes personales no patológicos - Número de comidas al día', campo: 'antecedentes_comidas_dia', tipo: 'string' },
      { nombre: 'Antecedentes personales no patológicos - Tipo de Habitación', campo: 'antecedentes_tipo_habitacion', tipo: 'string' },
      { nombre: 'Antecedentes personales no patológicos - Inmunizaciones', campo: 'antecedentes_inmunizaciones', tipo: 'string' },
      { nombre: 'Antecedentes personales no patológicos - Lugar de disposición de excretas', campo: 'antecedentes_lugar_excreciones', tipo: 'string' },
      { nombre: 'Antecedentes personales no patológicos - Escolaridad', campo: 'antecedentes_escolaridad', tipo: 'string' },
      { nombre: 'Antecedentes personales no patológicos - Tipo de sangre y RH', campo: 'antecedentes_tipo_sangre', tipo: 'string' },
      
      { nombre: 'Antecendentes Gineco-obstétricos - Fecha de última menstruación', campo: 'antecedentes_ultima_menstruacion', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Fecha de probable parto', campo: 'antecedentes_probable_parto', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Inicio de vida sexual activa', campo: 'antecedentes_inicio_vida_sexual', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Menarca', campo: 'antecedentes_menarca', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Intervalo entre menstruaciones', campo: 'antecedentes_intervalo_mestruaciones', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Duración del flujo menstrual', campo: 'antecedentes_duracion_flujo_menstrual', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Presencia de otras secreciones', campo: 'antecedentes_otras_secreciones', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Cantidad y caracteristicas del flujo menstrual', campo: 'antecedentes_flujo_menstrual', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Enfermedades de transmisión sexual', campo: 'antecedentes_enfermedades_sexuales', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Última citología cervical', campo: 'antecedentes_ultima_citologia_cervical', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Número de Parejas Sexuales', campo: 'antecedentes_num_parejas_sexuales', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Uso de métodos anticonceptivos', campo: 'antecedentes_metodos_anticonceptivos', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Gestas', campo: 'antecedentes_gestas', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Partos', campo: 'antecedentes_partos', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Cesáreas', campo: 'antecedentes_cesareas', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Abortos', campo: 'antecedentes_abortos', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Datos de alarma obstétricos', campo: 'antecedentes_alarma_obstetricos', tipo: 'string' },
      { nombre: 'Antecendentes Gineco-obstétricos - Antecedentes Perinatales', campo: 'antecedentes_perinatales', tipo: 'bool' },

      { nombre: 'Exploración Física - Piel', campo: 'exploracion_piel', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Cabeza y cuello', campo: 'exploracion_cabeza', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Tórax', campo: 'exploracion_torax', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Abdomen', campo: 'exploracion_abdomen', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Columna', campo: 'exploracion_columna', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Extremidades Superiores', campo: 'exploracion_extremidades_superiores', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Extremidades Inferiores', campo: 'exploracion_extremidades_inferiores', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Genitales', campo: 'exploracion_genitales', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Sistema Nervioso', campo: 'exploracion_sistema_nervioso', tipo: 'boolean', nota: true, valor_nota: false },
    ],


    meses: ['ene', 'feb','mar', 'abr', 'may', 'jun','jul', 'ago', 'sep', 'oct', 'nov', 'dic' ]
  }),
  methods: {
    obtenerDatos(){
      var valido = true;
      for (let i = 0; i < this.validaciones.length; i++) {
        if(this.editedItem[this.validaciones[i].campo] == null || (this.validaciones[i].tipo == 'string' && this.editedItem[this.validaciones[i].campo].length < 0)){
          valido = false;
          this.$refs.alert.setMessage("", `El Campo ${this.validaciones[i].nombre} es Obligatorio`);
          this.$refs.alert.open();
          break;
        }

        if(this.validaciones[i].tipo == 'boolean' && this.validaciones[i].nota == true && this.editedItem[this.validaciones[i].campo] == this.validaciones[i].valor_nota && ( this.editedItem[this.validaciones[i].campo+'_nota'] == null ||  this.editedItem[this.validaciones[i].campo+'_nota'].length < 0    )){
          valido = false;
          this.$refs.alert.setMessage("", `La Nota del Campo ${this.validaciones[i].nombre} es Obligatoria`);
          this.$refs.alert.open();
          break;
        }
      }

      //return (valido) ? this.editedItem : null;
      if(valido){
        var payload = JSON.parse(JSON.stringify(this.editedItem));
        payload.antecedentes_ultima_menstruacion = this.beatyFecha(payload.antecedentes_ultima_menstruacion);
        console.log(payload);
        return payload;
      }
      else return null;
    },
    restablecer(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
    },
    calcularFechaParto(){
      var date = moment(this.editedItem.antecedentes_ultima_menstruacion, "YYYY-MM-DD");
      date = date.subtract(7, 'days');
      date = date.add(365, 'days');
      date = date.subtract(90, 'days');
      //console.log(date.format('YYYY-MM-DD'))
      this.editedItem.antecedentes_probable_parto = this.beatyFecha(date.format('YYYY-MM-DD'));
    },
    beatyFecha(fecha){
      var fechaPartes = fecha.split("-");
      var mes_num = parseInt(fechaPartes[1]);
      return `${fechaPartes[2]}-${this.meses[mes_num-1]}-${fechaPartes[0]}`;
    }
  }
}
</script>