<template>
  <v-container style="max-width: 1800px !important;">
    <v-row style="max-width: 1800px !important;">
      <v-col cols="6">
        <v-autocomplete
          v-model="searchItem.id_unidad_medica"
          :items="unidades_medicas"
          outlined
          label="Unidad Medica"
          :item-text="codigoNombre"
          item-value="id_unidad_medica"
          :loading="loadingUnidadesMedicas"
          dense
          hide-details=""
          @change="getItems"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :return-value.sync="searchItem.fecha"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="searchItem.fecha"
              label="Fecha"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="searchItem.fecha"
            no-title
            scrollable
            @change="getItems"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="green"
              @click="menu1 = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="red"
              @click="$refs.menu1.save(searchItem.fecha)"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row style="max-width: 1800px !important;">
      <v-col
        v-for="(grupo, index) in promociones"
        :key="grupo.TipoTaller"
        cols="12"
      >
        <div class="text--h5">{{ tipoTallerNombre(grupo.TipoTaller) }}</div>
        <v-data-table
          v-if="visible"
          class="promociones"
          :headers="headers"
          :items="grupo.Promociones"
          :items-per-page="-1"
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:[`item.taller`]="{ item }">
            <template v-if="item.rowspan > 0">
              <td :rowspan="item.rowspan" class="text-center">
                {{ item.taller }}
                <v-icon @click="agregarPromocion(index, item)">mdi-plus</v-icon>
              </td>
            </template>
            <template v-else>
              <td style="display: none;"></td>
            </template>
          </template>
          <template v-slot:[`item.id_promotor`]="{ item }">
            <v-autocomplete
              v-model="item.id_promotor"
              label="Promotor"
              dense
              :items="promotores"
              item-text="nombre"
              item-value="id_promotor"
              hide-details
              outlined
              :loading="loadingPromotores"
              clearable
            >
            </v-autocomplete>
          </template>
          <template v-slot:[`item.inicio`]="{ item }">
            <vue-timepicker v-model="item.hora_inicio"></vue-timepicker>
          </template>
          <template v-slot:[`item.fin`]="{ item }">
            <vue-timepicker v-model="item.hora_fin"></vue-timepicker>
          </template>
          <template v-slot:[`item.num_hombres`]="{ item }">
            <VueNumberInput
              v-model="item.num_hombres"
              :min="0"
              controls
              size="small"
            ></VueNumberInput>
          </template>
          <template v-slot:[`item.num_mujeres`]="{ item }">
            <VueNumberInput
              v-model="item.num_mujeres"
              :min="0"
              controls
              size="small"
            ></VueNumberInput>
          </template>
          <template v-slot:[`item.num_infantes`]="{ item }">
            <VueNumberInput
              v-model="item.num_infantes"
              :min="0"
              controls
              size="small"
            ></VueNumberInput>
          </template>
          <template v-slot:[`item.lista_asistencia`]="{ item }">
            <template v-if="item.fichero != null && item.fichero.length > 0">
              <v-btn
                block
                color="primary"
                text
                small
                @click="nuevaPestana(`/api/Promociones/DescargarDocumento/${item.fichero}?jwt=${token}`)"
              >
                <v-icon>mdi-download</v-icon>
                Descargar Adjunto
              </v-btn>
              <v-icon @click="item.fichero = null" class="mx-2">
                mdi-file-document-remove
              </v-icon>
            </template>
            <v-file-input
              v-else
              v-model="item.archivo"
              outlined
              dense
              hide-details
              label="Archivo"
              @change="convertToBase64(item)"
            ></v-file-input>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="item.rowspan == 0"
              small
              @click="eliminarPromocion(index, item)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="red"
          block
          @click="save"
          :disabled="saving"
          :loading="saving"
          class="white--text"
        >
          <v-icon>mdi-content-save</v-icon>
          Guardar Talleres de Promoción a la Salud
        </v-btn>
        <br />
        <br />
        <br />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import VueTimepicker from "vue2-timepicker";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import moment from "moment";

export default {
  components: {
    VueTimepicker,
    VueNumberInput,
  },
  computed: {
    ...mapState(["id_unidad_medica_activa", "token"]),
  },
  data: () => ({
    searchItem: {
      fecha: null,
      id_unidad_medica: null,
    },
    menu1: false,
    tab: null,
    headers: [
      {
        text: "Taller",
        value: "taller",
        align: "center",
        sortable: false,
        width: "12%",
      },
      {
        text: "Promotor",
        value: "id_promotor",
        align: "center",
        sortable: false,
      },
      {
        text: "Hora de Inicio",
        value: "inicio",
        align: "center",
        sortable: false,
        width: "7%",
      },
      {
        text: "Hora de Fin",
        value: "fin",
        align: "center",
        sortable: false,
        width: "7%",
      },
      {
        text: "# Hombres",
        value: "num_hombres",
        align: "center",
        sortable: false,
        width: "11%",
      },
      {
        text: "# Mujeres",
        value: "num_mujeres",
        align: "center",
        sortable: false,
        width: "11%",
      },
      {
        text: "# Niños",
        value: "num_infantes",
        align: "center",
        sortable: false,
        width: "11%",
      },
      {
        text: "Lista Asistencia",
        value: "lista_asistencia",
        align: "center",
        sortable: false,
        width: "12%",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
    promociones: [],
    loadingUnidadesMedicas: false,
    unidades_medicas: [],
    loadingTalleres: false,
    tipo_talleres: [],
    loading: false,
    promotores: [],
    loadingPromotores: false,
    saving: false,
    visible: true,
  }),
  mounted() {
    this.searchItem.fecha = moment().format("YYYY-MM-DD");
    this.searchItem.id_unidad_medica = this.id_unidad_medica_activa;
    this.getUnidadesMedicas();
    this.getListaTalleres();
    this.getPromotores();
  },
  methods: {
    codigoNombre: (item) => `[${item.codigo}] - ${item.nombre}`,
    tipoTallerNombre(tipoTallerId) {
      const tipoTaller = this.tipo_talleres.find(t => t.id_tipo_taller === tipoTallerId);
      return tipoTaller ? tipoTaller.nombre : "Desconocido";
    },
    sortPromociones(items) {
      var ordenado = items.sort((a, b) => {
        if (a.posicion === b.posicion) {
          return a.fecha_hora_inicio.localeCompare(b.fecha_hora_inicio);
        }
        return a.posicion - b.posicion;
      });

      var ids_talleres = ordenado.map((x) => x.id_taller);
      var conteo_taller = this.contarRepeticiones(ids_talleres).map(
        (objeto) => {
          return { ...objeto, primera_aparicion: false };
        }
      );

      ordenado.forEach((taller) => {
        var idx = conteo_taller.findIndex((x) => x.valor == taller.id_taller);
        if (idx >= 0) {
          if (conteo_taller[idx].primera_aparicion == false) {
            conteo_taller[idx].primera_aparicion = true;
            taller.rowspan = conteo_taller[idx].conteo;
          } else {
            taller.rowspan = 0;
          }
        }
      });

      return ordenado;
    },
    contarRepeticiones(listaCadenas) {
      const conteo = {};

      listaCadenas.forEach((cadena) => {
        if (conteo[cadena]) {
          conteo[cadena]++;
        } else {
          conteo[cadena] = 1;
        }
      });

      const resultado = Object.keys(conteo).map((clave) => {
        return { valor: clave, conteo: conteo[clave] };
      });

      return resultado;
    },
    getUnidadesMedicas() {
      this.loadingUnidadesMedicas = true;
      axios
        .get("/Catalogos/ListarUnidadesMedicas?ignore_config=true")
        .then((response) => {
          this.unidades_medicas = response.data;
          this.searchItem.id_unidad_medica = this.id_unidad_medica_activa;
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingUnidadesMedicas = false;
        });
    },
    getListaTalleres() {
      this.loadingTalleres = true;
      axios
        .get("/Promociones/ListarTalleres/" + this.searchItem.id_unidad_medica)
        .then((response) => {
          this.tipo_talleres = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingTalleres = false;
        });
    },
    getPromotores() {
      this.loadingPromotores = true;
      axios
        .get("/Promociones/ListarPromotores")
        .then((response) => {
          this.promotores = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingPromotores = false;
        });
    },
    getItems() {
      if (
        this.searchItem.fecha == null ||
        this.searchItem.id_unidad_medica == null
      )
        return;

      this.loading = true;
      this.refresh();
      axios
        .get(
          `/Promociones/ListarPromociones/${this.searchItem.id_unidad_medica}/${this.searchItem.fecha}`
        )
        .then((response) => {
          // Establece las listas de promociones basadas en la respuesta
          if (response.data && Array.isArray(response.data)) {
            this.restablecerListas(response.data);
          } else {
            this.restablecerListas([]);
          }
        })
        .catch((error) => {
          console.log(error);
          this.restablecerListas([]);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    agregarPromocion(index, item) {
      var nueva_promocion = Object.assign({}, item);
      nueva_promocion.id_promocion = null;
      nueva_promocion.fecha_realizacion = null;
      nueva_promocion.fecha_inicio = "";
      nueva_promocion.fecha_fin = "";
      nueva_promocion.fecha_hora_inicio = moment().format("YYYYMMDDHHmmssSSS");
      nueva_promocion.num_mujeres = 0;
      nueva_promocion.num_hombres = 0;
      nueva_promocion.num_infantes = 0;
      nueva_promocion.rowspan = 1;
      nueva_promocion.archivo = null;
      nueva_promocion.fichero_b64 = null;
      nueva_promocion.fichero = null;
      this.promociones[index].Promociones.push(nueva_promocion);
      this.promociones[index].Promociones = this.sortPromociones(this.promociones[index].Promociones);
    },
    eliminarPromocion(index, item) {
      this.promociones[index].Promociones = this.promociones[index].Promociones.filter(
        (x) => x != item
      );
      this.promociones[index].Promociones = this.sortPromociones(this.promociones[index].Promociones);
    },
    restablecerListas(promocionesAgrupadas) {
      if (!Array.isArray(promocionesAgrupadas)) {
        promocionesAgrupadas = [];
      }
      
      // Asignar promociones basadas en la respuesta agrupada
      this.promociones = promocionesAgrupadas.map((grupo) => ({
        TipoTaller: grupo.tipoTaller,
        Promociones: Array.isArray(grupo.promociones) ? grupo.promociones.map((x) => ({ ...x, archivo: null, fichero_b64: null })) : [],
      }));
    },
    convertToBase64(item) {
      if (typeof item.archivo == "undefined" || item.archivo == null) return;
      const reader = new FileReader();

      reader.onload = () => {
        item.fichero_b64 = reader.result;
      };
      reader.readAsDataURL(item.archivo);
    },
    save() {
      var payload = [];
      payload = payload.concat(...this.promociones.map(grupo => grupo.Promociones));
      payload = payload.filter(
        (x) =>
          x.id_promotor != null &&
          x.hora_inicio != null &&
          x.hora_inicio.length > 0 &&
          x.hora_fin != null &&
          x.hora_fin.length > 0
      );

      this.saving = true;
      axios
        .post(
          `/Promociones/GuardarPromociones/${this.searchItem.id_unidad_medica}/${this.searchItem.fecha}`,
          payload
        )
        .then(() => {
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    nuevaPestana(url) {
      window.open(url, "_blank");
    },
    refresh(){
      this.visible = false;
      this.$nextTick(() => {
        this.visible = true;
      });
    }
  },
};
</script>

<style>
.promociones .v-data-table__wrapper {
  overflow: visible !important; /* Asegúrate de que el contenedor de la tabla permita que el menú se muestre */
}
</style>
