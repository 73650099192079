<template>

  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :color="color">{{icon}}</v-icon>
      </v-btn>
    </template>
    <span>Promedio: {{avg}}</span>
  </v-tooltip>
</template>


<script>
import axios from 'axios'

export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-earth',
    },
    urls: {
      type: Array,
      default: () => (['https://mi.ubga.org.mx/healthcheck'])
    },
    localStorageName: {
      type: String,
      default: 'ping-times'
    }
  },
  mounted(){
    var ping_times = localStorage.getItem(this.localStorageName);
    if(typeof ping_times == 'undefined' || ping_times == null || ping_times == 'undefined') ping_times = "[]";
    this.times = JSON.parse(ping_times);
    this.pos = Math.floor(Math.random() * this.urls.length);
    this.checkColor();
    this.ping();
  },
  destroy(){
    if(this.timeout_id != null) clearTimeout(this.timeout_id);
  },
  data: () => ({
    pos: 0,
    timeout_id: null,
    time: -1,
    times: [],
    avg: 0,
    color: '#9E9E9E'
  }),
  computed:{
    
  },
  methods: {
    ping(){
      this.timeout_id = null;
      let start = performance.now();
      axios
      .get(this.urls[this.pos])
      .then(() => {
        let end = performance.now();
        this.time = (this.urls[this.pos] ,parseInt((end - start)));
      })
      .catch(() => {
        this.time  = -1;
      })
      .finally(() => {
        this.pos = (this.pos + 1)% this.urls.length;
        this.times.unshift(this.time);
        this.times = this.times.filter((x,idx) => idx < 20);
        localStorage.setItem(this.localStorageName, JSON.stringify(this.times));
        this.checkColor();
        this.timeout_id = setTimeout(() => {
            this.ping();
          }, (this.time < 200 && this.time >= 0) ? 60000 : 10000);
      })
    },
    checkColor(){
      if(this.times.length == 0) {
        this.color = '#9E9E9E';
        this.avg = Infinity;
        return;
      }
      
      var fails = this.times.filter((x, idx) => idx < 3 && x == -1).length;
      if(fails >= 3) {
        this.color = '#F44336';
        return;
      }
      
      let sum = this.times.map(x => (x === -1 ? 350 : x)).reduce((acc, curr) => acc + curr, 0);
      let count = this.times.length;
      this.avg = sum / count;
      
      if(this.avg > 0 && this.avg <= 200) this.color = '#4CAF50';
      else if(this.avg > 200 && this.avg <= 501) this.color = '#FFEB3B';
      else this.color = '#F44336';
      
    }
      
  } 
}
</script>