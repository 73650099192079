<template>
  <div>
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-row>
      <v-col cols="6">
        <span class="font-weight-bold">Antecedentes heredofamiliares</span>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Atopias:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.familiar_atopias"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                  label="Si"
                  :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.familiar_atopias_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Diabetes:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.familiar_diabetes"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.familiar_diabetes_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Cáncer:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.familiar_cancer"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.familiar_cancer_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Otros:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              v-model="editedItem.familiar_otros"
              outlined
              hide-details
              dense
              rows="1"
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>
        <br>
        <br>
        <span class="font-weight-bold">Antecedentes personales patológicos</span>
        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Alergias:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_alergias"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_alergias_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Anemia:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_anemia"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_anemia_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Cáncer:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_cancer"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_cancer_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Enfermedades Respiratorias:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_enfermades_respiratorias"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_enfermades_respiratorias_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Enfermedades Exantematicas:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_enfermades_exantematicas"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_enfermades_exantematicas_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Hospitalizaciones Previas:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_hospitalizacion"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_hospitalizacion_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Infectocontagiosas:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_infecciones"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_infecciones_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Neurológicas:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_neurologicas"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_neurologicas_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Quirúrgicos:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_quirurgicos"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_quirurgicos_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Traumatológicos:
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.personal_traumatologicos"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Si"
                :value="true"
              ></v-radio>
              <v-radio
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.personal_traumatologicos_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Otros:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              v-model="editedItem.personal_otros"
              outlined
              hide-details
              dense
              rows="1"
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="6">
        <span class="font-weight-bold">Antecedentes personales patológicos y neonatales</span>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Clasificación de la familia:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_clasificacion_familia"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Edad a la que se embarazó del(a) paciente:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_edad_embarazo"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Donde llevo control prenatal:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_control_prenatal"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Complicaciones durante el embarazo:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_complicaciones_embarazo"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Donde fue el alumbramiento:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_lugar_alumbramiento"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Complicaciones durante el alumbramiento:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_complicaciones_alumbramiento"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Amerito maniobras de reanimación:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_maniobras_reanimacion"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Zoonosis:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_zoonosis"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Número de comidas al día:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_comidas_dia"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Tipo de Habitación:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_tipo_habitacion"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Inmunizaciones:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_inmunizaciones"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Lugar de disposición de excretas:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_lugar_excreciones"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Escolaridad:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_escolaridad"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Desarrollo motor fino:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_motor_fino"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Desarrollo motor grueso:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_motor_grueso"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Lenguaje:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_lenguaje"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Alimentación:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_alimentacion"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Tipo de sangre y RH:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.antecedentes_tipo_sangre"
              required
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="6">
        <span class="font-weight-bold">Exploración Física</span>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Piel
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_piel"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_piel_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Cabeza y cuello
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_cabeza"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_cabeza_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Tórax
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_torax"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_torax_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Abdomen
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_abdomen"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_abdomen_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Columna
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_columna"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_columna_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Extremidades Superiores
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_extremidades_superiores"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_extremidades_superiores_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Extremidades Inferiores
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_extremidades_inferiores"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_extremidades_inferiores_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Genitales
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_genitales"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_genitales_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Sistema Nervioso
          </v-col>
          <v-col cols="4" class="pa-0 ma-0">
            <v-radio-group
              v-model="editedItem.exploracion_sistema_nervioso"
              required
              row
              hide-details
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                label="Normal"
                :value="true"
              ></v-radio>
              <v-radio
                label="Anormal"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0">
            <v-textarea
              :rows="1"
              auto-grow
              v-model="editedItem.exploracion_sistema_nervioso_nota"
              outlined
              hide-details
              dense
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Otros Agregados:
          </v-col>
          <v-col cols="9" class="ma-0 pa-0">
            <v-textarea
              v-model="editedItem.exploracion_otros"
              outlined
              hide-details
              dense
              rows="1"
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VAlertDialog from '@/components/VAlertDialog.vue'

export default {
  components: {
    VAlertDialog
  },
  data: () => ({
    dialog: false,
    editedItem: {
      familiar_atopias: null,
      familiar_atopias_nota: null,
      familiar_diabetes: null,
      familiar_diabetes_nota: null,
      familiar_cancer: null,
      familiar_cancer_nota: null,
      familiar_otros: null,
      personal_alergias: null,
      personal_alergias_nota: null,
      personal_anemia: null,
      personal_anemia_nota: null,
      personal_cancer: null,
      personal_cancer_nota: null,
      personal_enfermades_respiratorias: null,
      personal_enfermades_respiratorias_nota: null,
      personal_enfermades_exantematicas: null,
      personal_enfermades_exantematicas_nota: null,
      personal_hospitalizacion: null,
      personal_hospitalizacion_nota: null,
      personal_infecciones: null,
      personal_infecciones_nota: null,
      personal_neurologicas: null,
      personal_neurologicas_nota: null,
      personal_quirurgicos: null,
      personal_quirurgicos_nota: null,
      personal_traumatologicos: null,
      personal_traumatologicos_nota: null,
      personal_otros: null,
      antecedentes_clasificacion_familia: null,
      antecedentes_edad_embarazo: null,
      antecedentes_control_prenatal: null,
      antecedentes_complicaciones_embarazo: null,
      antecedentes_lugar_alumbramiento: null,
      antecedentes_complicaciones_alumbramiento: null,
      antecedentes_maniobras_reanimacion: null,
      antecedentes_zoonosis: null,
      antecedentes_comidas_dia: null,
      antecedentes_tipo_habitacion: null,
      antecedentes_inmunizaciones: null,
      antecedentes_lugar_excreciones: null,
      antecedentes_escolaridad: null,
      antecedentes_motor_fino: null,
      antecedentes_motor_grueso: null,
      antecedentes_lenguaje: null,
      antecedentes_alimentacion: null,
      antecedentes_tipo_sangre: null,
      exploracion_piel: null,
      exploracion_piel_nota: null,
      exploracion_cabeza: null,
      exploracion_cabeza_nota: null,
      exploracion_torax: null,
      exploracion_torax_nota: null,
      exploracion_abdomen: null,
      exploracion_abdomen_nota: null,
      exploracion_columna: null,
      exploracion_columna_nota: null,
      exploracion_extremidades_superiores: null,
      exploracion_extremidades_superiores_nota: null,
      exploracion_extremidades_inferiores: null,
      exploracion_extremidades_inferiores_nota: null,
      exploracion_genitales: null,
      exploracion_genitales_nota: null,
      exploracion_sistema_nervioso: null,
      exploracion_sistema_nervioso_nota: null,
      exploracion_otros: null
    },
    defaultEditedItem: {
      familiar_atopias: null,
      familiar_atopias_nota: null,
      familiar_diabetes: null,
      familiar_diabetes_nota: null,
      familiar_cancer: null,
      familiar_cancer_nota: null,
      familiar_otros: null,
      personal_alergias: null,
      personal_alergias_nota: null,
      personal_anemia: null,
      personal_anemia_nota: null,
      personal_cancer: null,
      personal_cancer_nota: null,
      personal_enfermades_respiratorias: null,
      personal_enfermades_respiratorias_nota: null,
      personal_enfermades_exantematicas: null,
      personal_enfermades_exantematicas_nota: null,
      personal_hospitalizacion: null,
      personal_hospitalizacion_nota: null,
      personal_infecciones: null,
      personal_infecciones_nota: null,
      personal_neurologicas: null,
      personal_neurologicas_nota: null,
      personal_quirurgicos: null,
      personal_quirurgicos_nota: null,
      personal_traumatologicos: null,
      personal_traumatologicos_nota: null,
      personal_otros: null,
      antecedentes_clasificacion_familia: null,
      antecedentes_edad_embarazo: null,
      antecedentes_control_prenatal: null,
      antecedentes_complicaciones_embarazo: null,
      antecedentes_lugar_alumbramiento: null,
      antecedentes_complicaciones_alumbramiento: null,
      antecedentes_maniobras_reanimacion: null,
      antecedentes_zoonosis: null,
      antecedentes_comidas_dia: null,
      antecedentes_tipo_habitacion: null,
      antecedentes_inmunizaciones: null,
      antecedentes_lugar_excreciones: null,
      antecedentes_escolaridad: null,
      antecedentes_motor_fino: null,
      antecedentes_motor_grueso: null,
      antecedentes_lenguaje: null,
      antecedentes_alimentacion: null,
      antecedentes_tipo_sangre: null,
      exploracion_piel: null,
      exploracion_piel_nota: null,
      exploracion_cabeza: null,
      exploracion_cabeza_nota: null,
      exploracion_torax: null,
      exploracion_torax_nota: null,
      exploracion_abdomen: null,
      exploracion_abdomen_nota: null,
      exploracion_columna: null,
      exploracion_columna_nota: null,
      exploracion_extremidades_superiores: null,
      exploracion_extremidades_superiores_nota: null,
      exploracion_extremidades_inferiores: null,
      exploracion_extremidades_inferiores_nota: null,
      exploracion_genitales: null,
      exploracion_genitales_nota: null,
      exploracion_sistema_nervioso: null,
      exploracion_sistema_nervioso_nota: null,
      exploracion_otros: null
    },
    validaciones: [
      { nombre: 'Antecedentes heredofamiliares - Atopias', campo: 'familiar_atopias', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes heredofamiliares - Diabetes', campo: 'familiar_diabetes', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes heredofamiliares - Cáncer', campo: 'familiar_cancer', tipo: 'boolean', nota: true, valor_nota: true },

      { nombre: 'Antecedentes personales patológicos - Alergias', campo: 'personal_alergias', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Anemia', campo: 'personal_anemia', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Cáncer', campo: 'personal_cancer', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Enfermedades Respiratorias', campo: 'personal_enfermades_respiratorias', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Enfermedades Exantemáticas', campo: 'personal_enfermades_exantematicas', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Hospitalizaciones Previas', campo: 'personal_hospitalizacion', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Infectocontagiosas', campo: 'personal_infecciones', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Neurológicas', campo: 'personal_neurologicas', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Quirúrgicos', campo: 'personal_quirurgicos', tipo: 'boolean', nota: true, valor_nota: true },
      { nombre: 'Antecedentes personales patológicos - Traumatológicos', campo: 'personal_traumatologicos', tipo: 'boolean', nota: true, valor_nota: true },

      { nombre: 'Antecedentes personales patológicos y neonatales - Clasificación de la familia', campo: 'antecedentes_clasificacion_familia', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Edad a la que se embarazó del(a) paciente', campo: 'antecedentes_edad_embarazo', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Donde llevo control prenatal', campo: 'antecedentes_control_prenatal', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Complicaciones durante el embarazo', campo: 'antecedentes_complicaciones_embarazo', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Donde fue el alumbramiento', campo: 'antecedentes_lugar_alumbramiento', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Complicaciones durante el alumbramiento', campo: 'antecedentes_complicaciones_alumbramiento', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Amerito maniobras de reanimación', campo: 'antecedentes_maniobras_reanimacion', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Zoonosis', campo: 'antecedentes_zoonosis', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Número de comidas al día', campo: 'antecedentes_comidas_dia', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Tipo de Habitación', campo: 'antecedentes_tipo_habitacion', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Inmunizaciones', campo: 'antecedentes_inmunizaciones', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Lugar de disposición de excretas', campo: 'antecedentes_lugar_excreciones', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Escolaridad', campo: 'antecedentes_escolaridad', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Desarrollo motor fino', campo: 'antecedentes_motor_fino', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Desarrollo motor grueso', campo: 'antecedentes_motor_grueso', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Lenguaje', campo: 'antecedentes_lenguaje', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Alimentación', campo: 'antecedentes_alimentacion', tipo: 'string' },
      { nombre: 'Antecedentes personales patológicos y neonatales - Tipo de sangre y RH', campo: 'antecedentes_tipo_sangre', tipo: 'string' },

      { nombre: 'Exploración Física - Piel', campo: 'exploracion_piel', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Cabeza y cuello', campo: 'exploracion_cabeza', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Tórax', campo: 'exploracion_torax', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Abdomen', campo: 'exploracion_abdomen', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Columna', campo: 'exploracion_columna', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Extremidades Superiores', campo: 'exploracion_extremidades_superiores', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Extremidades Inferiores', campo: 'exploracion_extremidades_inferiores', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Genitales', campo: 'exploracion_genitales', tipo: 'boolean', nota: true, valor_nota: false },
      { nombre: 'Exploración Física - Sistema Nervioso', campo: 'exploracion_sistema_nervioso', tipo: 'boolean', nota: true, valor_nota: false },
    ]
  }),
  methods: {
    obtenerDatos(){
      var valido = true;
      for (let i = 0; i < this.validaciones.length; i++) {
        if(this.editedItem[this.validaciones[i].campo] == null || (this.validaciones[i].tipo == 'string' && this.editedItem[this.validaciones[i].campo].length < 0)){
          valido = false;
          this.$refs.alert.setMessage("", `El Campo ${this.validaciones[i].nombre} es Obligatorio`);
          this.$refs.alert.open();
          break;
        }

        if(this.validaciones[i].tipo == 'boolean' && this.validaciones[i].nota == true && this.editedItem[this.validaciones[i].campo] == this.validaciones[i].valor_nota && ( this.editedItem[this.validaciones[i].campo+'_nota'] == null ||  this.editedItem[this.validaciones[i].campo+'_nota'].length < 0    )){
          valido = false;
          this.$refs.alert.setMessage("", `La Nota del Campo ${this.validaciones[i].nombre} es Obligatoria`);
          this.$refs.alert.open();
          break;
        }
      }

      return (valido) ? this.editedItem : null;
    },
    restablecer(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
    }
  }
}
</script>