<template>
  <div>
    <v-file-input
      v-if="!isDownloadable"
      v-model="file"
      :dense="dense"
      :hide-details="hideDetails"
      :outlined="outlined"
      :label="label"
      :error-messages="errorMessages"
      clearable
      :accept="accept"
      @input="handleFileChange"
      @change="handleFileChange"
    ></v-file-input>
    
    <div v-else>
      <v-container class="ma-0 pa-0">
        <v-row class="pa-0 ma-0">
          <v-col cols="11">
            <div @click="downloadFile" class="custom-btn primary--text outlined-border">
              <v-icon>
                mdi-download
              </v-icon>
              Descargar archivo {{ label }}
            </div>
          </v-col>
          <v-col cols="1" class="text-center ma-0 pa-0 align-self-center">
            <v-icon @click="clearFile">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <span v-if="errorMessages != null && errorMessages.length > 0" class="red--text">{{ errorMessages }}</span>
    </div>
  </div>
</template>

<style scoped>
.custom-btn {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  white-space: normal;
  width: 100%;
  line-height: 1.2em;
  background-color: transparent;
}

/* Aplica el borde outlined con el color primario de Vuetify */
.outlined-border {
  border: 1px solid;
  border-color: var(--v-primary-base); /* Utiliza la variable de color primario */
}
</style>

<script>
export default {
  props: {
    value: {
      type: [String, Object, File],
      default: null
    },
    urlBase: {
      type: String,
      required: true,
      default: '/api/Proveedores/DescargarDocumento/'
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    accept: {
      type: String,
      default: () => undefined
    },
    maxSize: {
      type: Number,
      default: () => undefined
    }
  },
  data() {
    return {
      file: null
    };
  },
  computed: {
    isDownloadable() {
      return this.value && typeof this.value === 'string' && (this.file == null);
    },
    fileUrl() {
      return this.isDownloadable ? `${this.urlBase}${this.value}?jwt=${this.$store.state.token}` : null;
    }
  },
  methods: {
    downloadFile() {
      if (this.fileUrl) {
        window.open(this.fileUrl, '_blank');
      }
    },
    clearFile() {
      this.$emit('input', null);
      this.file = null;
    },
    handleFileChange() {
      if (this.file) {
        console.log(this.file);
        this.$emit('input', this.file); // Emite el archivo actualizado
      }
    }
  }
};
</script>
