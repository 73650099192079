<template>
	<v-toolbar color="white">
    <v-toolbar-title style="display: contents;">
			<v-img src="/img/logo_ubga.jpg" height="50px" max-width="60px" contain />
			<v-img src="/img/logotipo-600x100.png" height="50px" max-width="100px" contain />
		</v-toolbar-title>
		<v-spacer></v-spacer>
    <v-btn icon @click="home">
      <v-icon>mdi-home</v-icon>
    </v-btn>
		<v-btn
			v-if="mostrarVista(12)"
			icon
			@click="config"
		>
      <v-icon>mdi-cog</v-icon>
    </v-btn>
		<NetworkCheck>
		</NetworkCheck>
    <v-btn icon @click="logout">
      <v-icon>mdi-power</v-icon>
    </v-btn>
	</v-toolbar>
</template>

<script>
	import { mapGetters, mapState } from "vuex";
	import NetworkCheck from './NetworkCheck.vue';

	export default {
		components: {
			NetworkCheck
		},
		data: () => ({
			responsive: false,
			responsiveInput: false
		}),

		computed: {
			...mapGetters(["authorized"]),
			...mapState(['ids_vistas'])
		},

		watch: {
			$route(val) {
				this.title = val.meta.name;
			}
		},

		mounted() {
			this.onResponsiveInverted();
			window.addEventListener("resize", this.onResponsiveInverted);
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.onResponsiveInverted);
		},

		methods: {
			onClickBtn() {
				this.setDrawer(!this.$store.state.app.drawer);
			},
			onResponsiveInverted() {
				if (window.innerWidth < 991) {
					this.responsive = true;
					this.responsiveInput = false;
				} else {
					this.responsive = false;
					this.responsiveInput = true;
				}
			},
			home: function() {
					this.$router.push("/");
			},
			config: function() {
					this.$router.push("/dashboard/configuracion");
			},
			logout: function() {
				this.$store.dispatch("logout").then(() => {
					this.$router.push("/");
				});
			},
			mostrarVista(id_vista){
				if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
				return (this.ids_vistas.indexOf(id_vista) >= 0);
			}
		}
	};
</script>