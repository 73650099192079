<template>
  <div class="pa-3 ma-3">
    <v-alert-dialog ref="alert"></v-alert-dialog>
    <v-dialog max-width="65%" v-model="dialogAddEdit" persistent>
      <v-card>
        <v-card-title>
          {{ editedItem.id_diagnostico_medico === null ? "Crear" : "Editar" }} Diagnóstico Médico
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.codigo"
                label="Código"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.diagnostico"
                label="Diagnóstico"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.cie10"
                label="CIE-10"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.referencia_bibliografica"
                label="Referencia Bibliográfica"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="editedItem.ids_documentos_referencias_bibliograficas"
                :items="documentos"
                item-text="documento"
                item-value="id_documento_referencia_bibliografica"
                :loading="loadingDocumentos"
                multiple
                outlined
                dense
                hide-details
                chips
                deletable-chips
                label="Documentos de Referencia Bibliografica"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="65%" v-model="dialogDelete" persistent>
      <v-card>
        <v-card-title>
          Eliminar Diagnóstico Médico
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar el diagnóstico médico "{{ editedItem.diagnostico }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="openDialogAddEdit(null)">
          Crear Diagnóstico Médico
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table :items="items" :headers="headers" :loading="loading">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="openDialogAddEdit(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="openDialogDelete(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.documentos_referencias_bibliograficas`]="{ item }">
            <ul>
              <li v-for="(drb, idx) in item.documentos_referencias_bibliograficas" :key="`drb-${idx}`">
                {{drb}}
              </li>
            </ul>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import VAlertDialog from '@/components/VAlertDialog.vue';

export default {
  components: {
    VAlertDialog,
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers: [
      {
        text: 'Código',
        value: 'codigo',
        align: 'center',
        width: '15%',
      },
      {
        text: 'Diagnóstico',
        value: 'diagnostico',
        align: 'center',
        width: '25%',
      },
      {
        text: 'CIE-10',
        value: 'cie10',
        align: 'center',
        width: '20%',
      },
      {
        text: 'Referencia Bibliográfica',
        value: 'referencia_bibliografica',
        align: 'center',
        width: '30%',
      },
      {
        text: 'Documentos Referencias Bibliograficas',
        value: 'documentos_referencias_bibliograficas',
        align: 'center',
        width: '30%',
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
        width: '10%',
      },
    ],
    editedItem: {
      id_diagnostico_medico: null,
      diagnostico: '',
      codigo: '',
      cie10: '',
      referencia_bibliografica: '',
      documentos_referencias_bibliograficas: [],
      ids_documentos_referencias_bibliograficas: [],
    },
    defaultEditedItem: {
      id_diagnostico_medico: null,
      diagnostico: '',
      codigo: '',
      cie10: '',
      referencia_bibliografica: '',
      documentos_referencias_bibliograficas: [],
      ids_documentos_referencias_bibliograficas: [],
    },
    loadingSave: false,
    dialogDelete: false,
    loadingDelete: false,
    loadingDocumentos: false,
    documentos: []
  }),
  mounted() {
    this.getItems();
    this.getDocumentos();
  },
  methods: {
    getItems() {
      this.loading = true;
      axios
        .get('/Catalogos/ListarDiagnosticosMedicos')
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDocumentos() {
      this.loadingDocumentos = true;
      axios
        .get('/Catalogos/ListarDocumentosReferenciasBibliograficas')
        .then((response) => {
          this.documentos = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDocumentos = false;
        });
    },
    openDialogAddEdit(editItem = null) {
      if (editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit() {
      if (
        this.editedItem.codigo == null ||
        this.editedItem.codigo.length === 0 ||
        this.editedItem.codigo.length > 16
      ) {
        this.$refs.alert.setMessage(
          '',
          'Debe ingresar un Código entre 1 y 16 caracteres'
        );
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItem.diagnostico == null ||
        this.editedItem.diagnostico.length === 0 ||
        this.editedItem.diagnostico.length > 256
      ) {
        this.$refs.alert.setMessage(
          '',
          'Debe ingresar un Diagnostico entre 1 y 256 caracteres'
        );
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItem.cie10 == null ||
        this.editedItem.cie10.length === 0 ||
        this.editedItem.cie10.length > 16
      ) {
        this.$refs.alert.setMessage(
          '',
          'Debe ingresar un CIE-10 entre 1 y 16 caracteres'
        );
        this.$refs.alert.open();
        return;
      }

      if (
        this.editedItem.referencia_bibliografica == null ||
        this.editedItem.referencia_bibliografica.length === 0 ||
        this.editedItem.referencia_bibliografica.length > 256
      ) {
        this.$refs.alert.setMessage(
          '',
          'Debe ingresar una Referencia Bibliográfica entre 1 y 256 caracteres'
        );
        this.$refs.alert.open();
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_diagnostico_medico === null ? 'POST' : 'PUT',
        url:
          this.editedItem.id_diagnostico_medico === null
            ? '/Catalogos/CrearDiagnosticoMedico'
            : `/Catalogos/EditarDiagnosticoMedico/${this.editedItem.id_diagnostico_medico}`,
        data: {
          codigo: this.editedItem.codigo,
          diagnostico: this.editedItem.diagnostico,
          cie10: this.editedItem.cie10,
          referencia_bibliografica: this.editedItem.referencia_bibliografica,
          ids_documentos_referencias_bibliograficas: this.editedItem.ids_documentos_referencias_bibliograficas,
        },
      })
        .then(() => {
          this.getItems();
          this.closeDialogAddEdit();
        })
        .catch((error) => {
          if (error.response.status == 409) {
            this.$refs.alert.setMessage(
              '',
              'Ya existe un Diagnóstico Médico con el mismo Código.'
            );
            this.$refs.alert.open();
            return;
          }
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    openDialogDelete(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete() {
      this.loadingDelete = true;
      axios
        .delete(`/Catalogos/EliminarDiagnosticoMedico/${this.editedItem.id_diagnostico_medico}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
  },
};
</script>
