<template>
  <div>
    <apexchart
      v-if="show"
      :width="width"
      :height="height"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div v-else class="text-center">
      <v-progress-circular
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <br>
      <br>
      <div class="text-h6">Cargando</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: "250px"
    },
    useCurrencyFormat: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatMoney(number) {
        // Convierte el número a un string con dos decimales
        let numberString = number.toFixed(2);

        // Usa una expresión regular para agregar comas cada tres dígitos
        let formattedNumber = "$"+numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return formattedNumber;
    },
    setData(series, categories) {
      this.show = false;
      this.series = series;
      this.chartOptions = Object.assign({}, this.defaultChartOptions);
      this.chartOptions.xaxis.categories = categories;

      // Bind the context of `this` for the formatter function
      const self = this;
      this.chartOptions.yaxis.labels.formatter = function(value) {
        if (self.useCurrencyFormat) {
          return self.formatMoney(value);
        } else {
          return value;
        }
      };

      setTimeout(() => {
        this.show = true;
      }, 250); 
    }
  },
  data: () => ({
    show: false,
    //Datos de la Grafica
    series: [],

    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            return value;
          }
        }
      },
      xaxis: {
        categories: [],
      },
    },
    defaultChartOptions: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            return value;
          }
        }
      },
      xaxis: {
        categories: [],
      },
    },
  }),
}
</script>
