<template>
  <v-container style="max-width: 1800px !important;">
    <v-row style="max-width: 1800px !important;">
      <v-col cols="3">
        <v-card 
          :class="`text-center  ${(filtroPrioridad == 1) ? 'white--text' : 'black--text'}`"
          @click="cambiarPrioridad(1)"
          :disabled="!(filtroPrioridad == 1 || filtroPrioridad == null)"
          :color="(filtroPrioridad == 1) ? 'primary' : 'white'"
        >
          <div class="text-subtitle-2">
            <v-icon
              color="green"
            >
              mdi-circle
            </v-icon>
             Normal
          </div>
          <ICountUp
            :delay="1000"
            :endVal="normal"
            class="text-h6"
            :options="countupOptions"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card 
          :class="`text-center  ${(filtroPrioridad == 2) ? 'white--text' : 'black--text'}`"
          @click="cambiarPrioridad(2)"
          :disabled="!(filtroPrioridad == 2 || filtroPrioridad == null)"
          :color="(filtroPrioridad == 2) ? 'primary' : 'white'"
        >
          <div class="text-subtitle-2">
            <v-icon
              color="yellow"
            >
              mdi-circle
            </v-icon>
            Prioridad
          </div>
          <ICountUp
            :delay="1000"
            :endVal="prioridad"
            class="text-h6"
            :options="countupOptions"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          :class="`text-center  ${(filtroPrioridad == 3) ? 'white--text' : 'black--text'}`"
          @click="cambiarPrioridad(3)"
          :disabled="!(filtroPrioridad == 3 || filtroPrioridad == null)"
          :color="(filtroPrioridad == 3) ? 'primary' : 'white'"
        >
          <div class="text-subtitle-2">
            <v-icon
              color="orange"
            >
              mdi-circle
            </v-icon>
             Urgencia
          </div>
          <ICountUp
            :delay="1000"
            :endVal="urgencia"
            class="text-h6"
            :options="countupOptions"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          :class="`text-center  ${(filtroPrioridad == 4) ? 'white--text' : 'black--text'}`"
          @click="cambiarPrioridad(4)"
          :disabled="!(filtroPrioridad == 4 || filtroPrioridad == null)"
          :color="(filtroPrioridad == 4) ? 'primary' : 'white'"
        >
          <div class="text-subtitle-2">
            <v-icon
              color="red"
            >
              mdi-circle
            </v-icon>
            Emergencia
          </div>
          <ICountUp
            :delay="1000"
            :endVal="emergencia"
            class="text-h6"
            :options="countupOptions"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row style="max-width: 1800px !important;">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
        >
          <template v-slot:[`item.fotografia`]="{ item }">
            <v-container>  
              <br>
              <v-img
                height="200"
                :src="(item.fotografia != null) ? `/api/archivos/pacientes/${item.fotografia}?jwt=${token}` : '/img/persona.jpg'"
                contain
                width="200"
              ></v-img>
            </v-container>
          </template>
          <template v-slot:[`item.nombre_completo`]="{ item }">
            {{item.nombre}} {{item.apellido_paterno}} {{item.apellido_materno}}
          </template>
          <template v-slot:[`item.signos_vitales`]="{ item }">
            <div>
              <v-icon
                color="green darken-2"
              >
                mdi-weight-kilogram
              </v-icon>
              <span class="text-body-2">
                Peso: {{ item.signos_vitales.peso }} Kg
              </span>
            </div>
            <div>
              <v-icon
                color="blue darken-2"
              >
                mdi-human-male-height-variant
              </v-icon>
              <span class="text-body-2">
                Estatura: {{ item.signos_vitales.estatura }} m
              </span>
            </div>
            <div>
              <v-icon
                color="purple darken-2"
              >
                mdi-percent-outline
              </v-icon>
              <span class="text-body-2">
                IMC: {{ item.signos_vitales.imc }}
              </span>
            </div>
            <div
              v-if="item.signos_vitales.cintura != null"
            >
              <v-icon
                color="teal darken-2"
              >
                mdi-tape-measure
              </v-icon>
              <span class="text-body-2">
                Cintura: {{ item.signos_vitales.cintura }} cm
              </span>
            </div>
            <div>
              <v-icon
                color="blue-grey darken-2"
              >
                mdi-thermometer
              </v-icon>
              <span class="text-body-2">
                Temperatura: {{ item.signos_vitales.temperatura }}° C
              </span>
            </div>
            <div>
              <v-icon
                color="red darken-2"
              >
                mdi-heart
              </v-icon>
              <span class="text-body-2">
                Pulso: {{ item.signos_vitales.pulso }} ppm
              </span>
            </div>
            <div>
              <v-icon
                color="orange darken-2"
              >
                mdi-lungs
              </v-icon>
              <span class="text-body-2">
                Respiración: {{ item.signos_vitales.respiracion }} rpm
              </span>
            </div>
            <div>
              <v-icon
                color="indigo darken-2"
              >
                mdi-counter
              </v-icon>
              <span class="text-body-2">
                Oximetría: {{ item.signos_vitales.oximetria }}% Sp02
              </span>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <template v-if="item.en_progreso == true">
              <v-icon color="green" x-large>mdi-progress-check</v-icon>
              <span class="text-subtitle-1 green--text">En Progreso</span>
              <br>
            </template>
            <template v-if="item.nivel_urgencia == 1">
              <v-icon color="green" x-large>mdi-ambulance</v-icon>
              <span class="text-subtitle-1 green--text">Normal</span>
            </template>
            <template v-if="item.nivel_urgencia == 2">
              <v-icon color="yellow" x-large>mdi-ambulance</v-icon>
              <span class="text-subtitle-1 yellow--text">Prioridad</span>
            </template>
            <template v-if="item.nivel_urgencia == 3">
              <v-icon color="orange" x-large>mdi-ambulance</v-icon>
              <span class="text-subtitle-1 orange--text">Urgencia</span>
            </template>
            <template v-if="item.nivel_urgencia == 4">
              <v-icon color="red" x-large>mdi-ambulance</v-icon>
              <span class="text-subtitle-1 red--text">Emergencia</span>
            </template>

            <v-btn
              color="green"
              class="white--text"
              block
              @click="iniciarConsulta(item)"
            >
              Iniciar Consulta
            </v-btn>
            <br>
            <v-btn
              text
              color="red"
              block
              @click="cancelarConsulta(item)"
            >
              Cancelar Consulta
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <CancelarConsulta
      ref="cancelarConsulta"
      url="/ConsultasDentales/CancelarConsulta"
      @change="getConsultasDentales"
    >
    </CancelarConsulta>
    <consulta
      ref="consulta"
      @complete="finalizarConsulta"
    >
    </consulta>
  </v-container>
</template>

<script>
import ICountUp from 'vue-countup-v2';
import Consulta from './ConsultaDental/Consulta.vue'
import axios from 'axios'
import { mapState } from 'vuex'
import CancelarConsulta from '@/components/DashViews/Comunes/CancelarConsulta.vue'

export default {
  components: {
    ICountUp,
    Consulta,
    CancelarConsulta
  },
  computed: {
    ...mapState(['token'])
  },
  data: () => ({
    headers: [
      {
        text: "Fotografia",
        value: "fotografia",
        align: "center",
        width: "10%",
        sortable: false,
      },
      {
        text: "Folio",
        value: "folio",
        align: "center",
        width: "10%",
        sortable: false,
      },
      {
        text: "Nombre Completo",
        value: "nombre_completo",
        align: "center",
        width: "15%",
        sortable: false,
      },
      {
        text: "Edad",
        value: "edad",
        align: "center",
        width: "10%",
        sortable: false,
      },
      {
        text: "Sexo",
        value: "sexo",
        align: "center",
        width: "10%",
        sortable: false,
      },
      {
        text: "Signos Vitales",
        value: "signos_vitales",
        align: "center",
        width: "15%",
        sortable: false,
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%",
        sortable: false,
      },
    ],
    items: [],
    loading: false,
    countupOptions: {
      suffix: ' Consultas'
    },
    filtroPrioridad: null,
    normal: 0,
    prioridad: 0,
    urgencia: 0,
    emergencia: 0
  }),
  mounted() {
    this.getConsultasDentales();
  },
  methods: {
    getItems(){
      this.getConsultasDentales();
    },
    getConsultasDentales(){
      this.loading = true;
      this.items = [];

      var url = (this.filtroPrioridad == null) ? '/ConsultasDentales/ListarConsultas' : `/ConsultasDentales/ListarConsultas?nivel_urgencia=${this.filtroPrioridad}`

      axios
      .get(url)
      .then(response => {
        this.items = response.data.consultas_dentales;
        if(this.filtroPrioridad == null){
          this.normal = response.data.num_normal;
          this.prioridad = response.data.num_prioridad;
          this.urgencia = response.data.num_urgencia;
          this.emergencia = response.data.num_emergencia;

          this.consultaEnProgreso();
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    consultaEnProgreso(){
      axios
      .get('/ConsultasDentales/ConsultaEnProgreso')
      .then(response => {
        if(response.data != null && response.data.length > 0){
          this.$refs.consulta.iniciarConsulta(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    iniciarConsulta(item){
      this.$refs.consulta.iniciarConsulta(item.id_paciente_servicio);
      this.getConsultasDentales();
    },
    cambiarPrioridad(prioridad){
      if(this.filtroPrioridad != prioridad) this.filtroPrioridad = prioridad;
      else if(this.filtroPrioridad != null) this.filtroPrioridad = null;
      else this.filtroPrioridad = prioridad;
      this.getConsultasDentales();
    },
    finalizarConsulta(){
      this.getConsultasDentales();
    },
    cancelarConsulta(item){
      this.$refs.cancelarConsulta.openDialog(item);
    }
  }
}
</script>