<template>
  <div class="pa-3 ma-3">
    <v-dialog v-model="dialogDetail" persistent max-width="70%">
      <v-card>
        <v-card-title>
          Detalles de la Receta Medica
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <span class="font-weight-bold">Paciente:</span> {{detailItem.nombre}} {{detailItem.apellido_paterno}} {{detailItem.apellido_materno}}
              <br>
              <span class="font-weight-bold">Médico Tratante:</span> {{detailItem.doctor_nombre}} {{detailItem.doctor_apellido_paterno}} {{detailItem.doctor_apellido_materno}}
              <br>
              <span class="font-weight-bold">Servicio:</span> {{detailItem.servicio}}
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <span class="font-weight-bold">Folio:</span> {{detailItem.folio}}
              <br>
              <span class="font-weight-bold">Fecha:</span> {{detailItem.fecha}}
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <v-data-table
                :items="detailItem.medicamentos"
                :headers="headersMedicamentos"
                :items-per-page="-1"
                hide-default-footer
              >
                <!--
                <template v-slot:[`item.actions`]="{ item }">
                    <v-radio-group
                      v-model="item.estado"
                      row
                    >
                      <v-radio
                        :value="true"
                      >
                        <template v-slot:label>
                          <div class="green--text"><strong >Autorizar</strong></div>
                        </template>
                      </v-radio>
                      <v-radio
                        :value="false"
                      >
                        <template v-slot:label>
                          <div class="red--text"><strong >Rechazar</strong></div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <template
                      v-if="item.estado == false"
                    >
                      <v-autocomplete
                        v-model="item.id_almacen_motivo_rechazo"
                        :items="motivos_rechazo"
                        item-text="nombre"
                        item-value="id_almacen_motivo_rechazo"
                        dense
                        hide-details
                        outlined
                        label="Motivo de Rechazo"
                      >

                      </v-autocomplete>
                      <br>
                      <v-textarea
                        v-model="item.anotacion"
                        dense
                        hide-details
                        outlined
                        label="Anotación"
                        rows="1"
                        auto-grow
                      >  
                      </v-textarea>
                      <br>
                    </template>
                </template>
                -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="closeDialog">Cancelar</v-btn>
          <v-btn text color="red" @click="save">Entregar Medicamentos</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="4">
        <v-autocomplete
          v-model="busquedaItem.id_unidad_medica"
          :items="unidades_medicas"
          outlined
          label="Unidad Medica"
          :item-text="codigoNombre"
          item-value="id_unidad_medica"
          :loading="loadingUnidadesMedicas"
          dense
          hide-details
          @change="getItems"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="busquedaItem.id_almacen"
          outlined
          hide-details
          dense
          label="Farmacia UMM"
          :loading="loadingAlmacenes"
          :items="almacenes"
          item-text="nombre"
          item-value="id_almacen"
          clearable
          @change="getItems"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="busquedaItem.id_usuario"
          :items="medicos"
          outlined
          label="Médico"
          :item-text="nombreMedico"
          item-value="id_usuario"
          :loading="loadingMedicos"
          dense
          hide-details
          @change="getItems"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        Rango de Fechas: 
        <date-picker v-model="busquedaItem.rango" range valueType="format" format="DD-MM-YYYY" @change="getItems"></date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :items="items" :headers="headers" :loading="loading">
          <template v-slot:[`item.doctor`]="{ item }">
            {{item.doctor_nombre}} {{item.doctor_apellido_paterno}} {{item.doctor_apellido_materno}}
          </template>
          <template v-slot:[`item.paciente`]="{ item }">
            {{item.nombre}} {{item.apellido_paterno}} {{item.apellido_materno}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="openMostrarDetalles(item)">
                  mdi-check
              </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>



<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

import axios from 'axios'


export default {
  components: {
    DatePicker
  },
  data: () => ({
    loading: false,
    items: [],
    headers: [
      {
          text: 'Fecha',
          value: 'fecha',
          align: 'center'
      },
      {
          text: 'Folio',
          value: 'folio',
          align: 'center'
      },
      {
          text: 'Servicio',
          value: 'servicio',
          align: 'center'
      },
      /*
      {
          text: 'Nombre',
          value: 'nombre',
          align: 'center'
      },
      {
          text: 'Apellido Paterno',
          value: 'apellido_paterno',
          align: 'center'
      },
      {
          text: 'Apellido Materno',
          value: 'apellido_materno',
          align: 'center'
      },
      */
      {
          text: 'Paciente',
          value: 'paciente',
          align: 'center'
      },
      {
          text: 'Médico Tratante',
          value: 'doctor',
          align: 'center'
      },
      {
          text: 'Acciones',
          value: 'actions',
          align: 'center',
          width: '10%'
      },
    ],
    headersMedicamentos: [
      {
          text: 'Cantidad',
          value: 'cantidad',
          align: 'center'
      },
      {
          text: 'Medicamento',
          value: 'medicamento',
          align: 'center'
      }/*,
      {
          text: 'Acciones',
          value: 'actions',
          align: 'center',
          width: '40%'
      }*/
    ],
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
      },
      monthBeforeYear: false,
    },
    busquedaItem: {
      rango: null,
      id_almacen: null,
      id_unidad_medica: null,
    },
    loadingAlmacenes: false,
    almacenes: [],
    detailItem: {
      fecha: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      servicio: null,
      folio: null,
      medicamentos: []
    },
    defaultDetailItem: {
      fecha: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      servicio: null,
      folio: null,
      medicamentos: []
    },
    dialogDetail: false,
    motivos_rechazo: [],
    unidades_medicas: [],
    loadingUnidadesMedicas: false,
    medicos: [],
    loadingMedicos: false,

  }),
  mounted() {
    this.getItems();
    this.getAlmacenes();
    this.getMotivosRechazo();
    this.getUnidadesMedicas();
    this.getMedicos();
  },
  methods: {
    codigoNombre: item => `[${item.codigo}] - ${item.nombre}`,
    nombreMedico: item => `${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}`,
    getAlmacenes() {
      this.loadingAlmacenes = true;
      axios.get('/Almacenes/ListarAlmacenes')
        .then(response => {
          this.almacenes = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAlmacenes = false;
        })
    },
    getMotivosRechazo() {
      this.loading = true;
      axios.get('/Almacenes/ListarAlmacenMotivosRechazo')
        .then(response => {
          this.motivos_rechazo = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getUnidadesMedicas(){
      this.loadingUnidadesMedicas = true;
      axios
      .get('/Catalogos/ListarUnidadesMedicas?ignore_config=true')
      .then(response => {
        this.unidades_medicas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingUnidadesMedicas = false;
      })
    },
    getMedicos(){
      this.loadingMedicos = true;
      axios
      .get('/Almacenes/ListarMedicos')
      .then(response => {
        this.medicos = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingMedicos = false;
      })
    },
    getItems() {
      this.items = [];

      const url = new URL(`http://localhost/Almacenes/RecetasPendientes`);
      if(this.busquedaItem.id_almacen != null) url.searchParams.append("id_almacen", this.busquedaItem.id_almacen);
      if(this.busquedaItem.rango != null && this.busquedaItem.rango.length == 2) {
        if(this.busquedaItem.rango[0] != null) url.searchParams.append("desde", this.busquedaItem.rango[0]);
        if(this.busquedaItem.rango[1] != null) url.searchParams.append("hasta", this.busquedaItem.rango[1]);
      }
      if(this.busquedaItem.id_unidad_medica != null) url.searchParams.append("id_unidad_medica", this.busquedaItem.id_unidad_medica);
      if(this.busquedaItem.id_usuario != null) url.searchParams.append("id_usuario", this.busquedaItem.id_usuario);
      var url_string = url.href.replace('http://localhost', '');


      this.loading = true;
      axios.get(url_string)
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    openMostrarDetalles(item){
      this.detailItem = item;
      this.detailItem.medicamentos = this.detailItem.medicamentos.map(obj => ({ ...obj, estado: null, id_almacen_motivo_rechazo: null, anotacion: null }))
      this.dialogDetail = true;
    },
    closeDialog(){
      this.detailItem = Object.assign({}, this.defaultDetailItem);
      this.detailItem.medicamentos = [];
      this.dialogDetail = false;
    },
    save(){
      this.saving = true;

      var ids_movimientos_completos = this.detailItem.medicamentos.map(x => x.id_almacen_movimiento);

      axios
      .post('/Almacenes/FinalizarRecetaMedica', {
        ids_movimientos_completos: ids_movimientos_completos,
        movimientos_rechazados: [],
      })
      .then(() => {
        this.closeDialog();
        this.getItems();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.saving = false;
      })
    }
  }
}
</script>
