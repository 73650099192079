<template>
  <div>
    <v-alert-dialog ref="alert"> </v-alert-dialog>
    <!--Barra de navegacion que se va a mostrar cuando se seleccione el check-->
    <v-navigation-drawer
      v-model="dialog"
      fixed
      temporary
      right
      :width="500"
      :permanent="dialog"
    >
      <v-toolbar dark color="primary" style="margin-bottom: 15px">
        <v-btn icon dark @click="closeDialog(currentTooth.numero)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title style="padding: 20px"> Detalle del Diente </v-card-title>
      </v-toolbar>

      <v-card-text class="pt-0">
        <v-row class="ma-0 pa-0">
          <v-col cols="4" class="ma-0 pa-0">
            <v-img :src="currentTooth.imagen" height="150px" contain></v-img>
          </v-col>
          <v-col cols="8">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0 pt-2">
                <v-text-field
                  v-model="currentTooth.tipo"
                  readonly
                  label="Dentadura:"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="ma-0 pa-0 pt-2">
                <v-text-field
                  v-model="currentTooth.posicion"
                  readonly
                  label="Posicion"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="ma-0 pa-0 pt-2">
                <v-text-field
                  v-model="currentTooth.numero"
                  readonly
                  label="Número de Órgano Dental:"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="currentTooth.caras"
              :items="caras"
              item-value="id_cara"
              item-text="nombre"
              label="Cara del Órgano Dental:"
              multiple
              chips
              deletable-chips
              hide-details
              outlined
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="currentTooth.diagnosticos"
              :items="diagnosticos"
              item-value="id_diagnostico"
              item-text="nombre"
              label="Diagnóstico del Órgano Dental:"
              multiple
              chips
              deletable-chips
              hide-details
              outlined
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="currentTooth.procedimientos"
              :items="procedimientos"
              item-value="id_procedimiento"
              item-text="nombre"
              label="Procedimiento del Órgano Dental"
              multiple
              chips
              deletable-chips
              hide-details
              outlined
            ></v-autocomplete>
          </v-col>
        </v-row>

        <!--
        <v-row v-if="dientes_acciones.length > 0">
          <v-col cols="12">
            <v-divider></v-divider>
            <br />
            <v-autocomplete
              v-model="copyItem"
              return-object
              :items="dientes_acciones"
              :item-text="copiarLabel"
              label="Copiar Información de Otro Diente"
              dense
              hide-details
              outlined
              @change="copiarDiente"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        -->
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" class="white--text" @click="save" width="100%"
          >Aceptar</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-navigation-drawer>
    <!--Barra de navegacion que se va a mostrar cuando se seleccione el check-->

    <v-row class="dientes">
      <v-col cols="12" md="6">
        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 8.png" width="50" height="100%" />

            <div class="check">
              <div>
                <v-checkbox
                  value="11"
                  v-model="checkedNames"
                  @change="openDialog('Permanente', 'Superior', 11, $event)"
                  :disabled="isCheckedDisabled(11)"
                ></v-checkbox>
              </div>
            </div>
          </div>

          <span style="font-size: 20px">11</span>
        </v-col>

        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 7.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="12"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 12, $event)"
                :disabled="isCheckedDisabled(12)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">12</span>
        </v-col>

        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 6.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="13"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 13, $event)"
                :disabled="isCheckedDisabled(13)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">13</span>
        </v-col>

        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 5.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="14"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 14, $event)"
                :disabled="isCheckedDisabled(14)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">14</span>
        </v-col>

        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 4.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="15"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 15, $event)"
                :disabled="isCheckedDisabled(15)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">15</span>
        </v-col>

        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 3.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="16"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 16, $event)"
                :disabled="isCheckedDisabled(16)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">16</span>
        </v-col>

        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 2.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="17"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 17, $event)"
                :disabled="isCheckedDisabled(17)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">17</span>
        </v-col>

        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 1.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="18"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 18, $event)"
                :disabled="isCheckedDisabled(18)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">18</span>
        </v-col>
      </v-col>

      <v-col cols="12" md="6">
        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 9.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="21"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 21, $event)"
                :disabled="isCheckedDisabled(21)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">21</span>
        </v-col>

        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 10.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="22"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 22, $event)"
                :disabled="isCheckedDisabled(22)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">22</span>
        </v-col>

        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 11.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="23"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 23, $event)"
                :disabled="isCheckedDisabled(23)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">23</span>
        </v-col>

        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 12.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="24"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 24, $event)"
                :disabled="isCheckedDisabled(24)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">24</span>
        </v-col>

        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 13.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="25"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 25, $event)"
                :disabled="isCheckedDisabled(25)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">25</span>
        </v-col>

        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 14.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="26"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 26, $event)"
                :disabled="isCheckedDisabled(26)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">26</span>
        </v-col>

        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 15.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="27"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 27, $event)"
                :disabled="isCheckedDisabled(27)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">27</span>
        </v-col>

        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/dientes2/SUP 16.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="28"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Superior', 28, $event)"
                :disabled="isCheckedDisabled(28)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">28</span>
        </v-col>
      </v-col>

      <v-col cols="12" md="6">
        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/infantil/SUP 5.png" width="50" height="100%" />

            <div class="check">
              <div>
                <v-checkbox
                  value="51"
                  v-model="checkedNames"
                  @change="openDialog('Decidua', 'Superior', 51, $event)"
                  :disabled="isCheckedDisabled(51)"
                ></v-checkbox>
              </div>
            </div>
          </div>

          <span style="font-size: 20px">51</span>
        </v-col>

        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/infantil/SUP 4.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="52"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Superior', 52, $event)"
                :disabled="isCheckedDisabled(52)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">52</span>
        </v-col>

        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/infantil/SUP 3.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="53"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Superior', 53, $event)"
                :disabled="isCheckedDisabled(53)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">53</span>
        </v-col>

        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/infantil/SUP 2.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="54"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Superior', 54, $event)"
                :disabled="isCheckedDisabled(54)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">54</span>
        </v-col>

        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <v-img src="/img/infantil/SUP 1.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="55"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Superior', 55, $event)"
                :disabled="isCheckedDisabled(55)"
              ></v-checkbox>
            </div>
          </div>
          <div class="check">
            <!-- <v-checkbox :class="{ filled: teeth[0].filled }" value="55_4" v-model="checkedNames"></v-checkbox>
     -->
          </div>

          <span style="font-size: 20px">55</span>
        </v-col>
      </v-col>

      <v-col cols="12" md="6">
        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/infantil/SUP 7.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="61"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Superior', 61, $event)"
                :disabled="isCheckedDisabled(61)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">61</span>
        </v-col>

        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/infantil/SUP 8.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="62"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Superior', 62, $event)"
                :disabled="isCheckedDisabled(62)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">62</span>
        </v-col>

        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/infantil/SUP 9.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="63"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Superior', 63, $event)"
                :disabled="isCheckedDisabled(63)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">63</span>
        </v-col>

        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/infantil/SUP 9.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="64"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Superior', 64, $event)"
                :disabled="isCheckedDisabled(64)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">64</span>
        </v-col>

        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <v-img src="/img/infantil/SUP 10.png" width="50" height="100%" />

            <div class="check">
              <v-checkbox
                value="65"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Superior', 65, $event)"
                :disabled="isCheckedDisabled(65)"
              ></v-checkbox>
            </div>
          </div>

          <span style="font-size: 20px">65</span>
        </v-col>
      </v-col>

      <v-col cols="12" md="6">
        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">81</span>

            <div class="check">
              <v-checkbox
                value="81"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 81, $event)"
                :disabled="isCheckedDisabled(81)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 5.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">82</span>

            <div class="check">
              <v-checkbox
                value="82"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 82, $event)"
                :disabled="isCheckedDisabled(82)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 4.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">83</span>

            <div class="check">
              <v-checkbox
                value="83"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 83, $event)"
                :disabled="isCheckedDisabled(83)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 3.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">84</span>

            <div class="check">
              <v-checkbox
                value="84"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 84, $event)"
                :disabled="isCheckedDisabled(84)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 2.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="2" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">85</span>

            <div class="check">
              <v-checkbox
                value="85"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 85, $event)"
                :disabled="isCheckedDisabled(85)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 1.png" width="50" height="100%" />
          </center>
        </v-col>
      </v-col>

      <v-col cols="12" md="6">
        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">71</span>

            <div class="check">
              <v-checkbox
                value="71"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 71, $event)"
                :disabled="isCheckedDisabled(71)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 6.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">72</span>

            <div class="check">
              <v-checkbox
                value="72"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 72, $event)"
                :disabled="isCheckedDisabled(72)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 7.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">73</span>

            <div class="check">
              <v-checkbox
                value="73"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 73, $event)"
                :disabled="isCheckedDisabled(73)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 8.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">74</span>

            <div class="check">
              <v-checkbox
                value="74"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 74, $event)"
                :disabled="isCheckedDisabled(74)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 9.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="2"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">75</span>

            <div class="check">
              <v-checkbox
                value="75"
                v-model="checkedNames"
                @change="openDialog('Decidua', 'Inferior', 75, $event)"
                :disabled="isCheckedDisabled(75)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/infantil/INF 10.png" width="50" height="100%" />
          </center>
        </v-col>
      </v-col>

      <v-col cols="12" md="6">
        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">41</span>

            <div class="check">
              <v-checkbox
                value="41"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 41, $event)"
                :disabled="isCheckedDisabled(41)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 8.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">42</span>

            <div class="check">
              <v-checkbox
                value="42"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 42, $event)"
                :disabled="isCheckedDisabled(42)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 7.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">43</span>

            <div class="check">
              <v-checkbox
                value="43"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 43, $event)"
                :disabled="isCheckedDisabled(43)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 6.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">44</span>

            <div class="check">
              <v-checkbox
                value="44"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 44, $event)"
                :disabled="isCheckedDisabled(44)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 5.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">45</span>

            <div class="check">
              <v-checkbox
                value="45"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 45, $event)"
                :disabled="isCheckedDisabled(45)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 4.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">46</span>

            <div class="check">
              <v-checkbox
                value="46"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 46, $event)"
                :disabled="isCheckedDisabled(46)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 3.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">47</span>

            <div class="check">
              <v-checkbox
                value="47"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 47, $event)"
                :disabled="isCheckedDisabled(47)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 2.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col cols="3" md="2" style="text-align: center">
          <div class="mouth">
            <span style="font-size: 20px; position: relative">48</span>

            <div class="check">
              <v-checkbox
                value="48"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 48, $event)"
                :disabled="isCheckedDisabled(48)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 1.png" width="50" height="100%" />
          </center>
        </v-col>
      </v-col>

      <v-col cols="12" md="6">
        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">31</span>

            <div class="check">
              <v-checkbox
                value="31"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 31, $event)"
                :disabled="isCheckedDisabled(31)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 9.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">32</span>

            <div class="check">
              <v-checkbox
                value="32"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 32, $event)"
                :disabled="isCheckedDisabled(32)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 10.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">33</span>

            <div class="check">
              <v-checkbox
                value="33"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 33, $event)"
                :disabled="isCheckedDisabled(33)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 11.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">34</span>

            <div class="check">
              <v-checkbox
                value="34"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 34, $event)"
                :disabled="isCheckedDisabled(34)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 12.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">35</span>

            <div class="check">
              <v-checkbox
                value="35"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 35, $event)"
                :disabled="isCheckedDisabled(35)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 13.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">36</span>

            <div class="check">
              <v-checkbox
                value="36"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 36, $event)"
                :disabled="isCheckedDisabled(36)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 14.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">37</span>

            <div class="check">
              <v-checkbox
                value="37"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 37, $event)"
                :disabled="isCheckedDisabled(37)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 15.png" width="50" height="100%" />
          </center>
        </v-col>
        <v-col
          cols="3"
          md="2"
          style="text-align: center; float: left !important"
        >
          <div class="mouth">
            <span style="font-size: 20px; position: relative">38</span>

            <div class="check">
              <v-checkbox
                value="38"
                v-model="checkedNames"
                @change="openDialog('Permanente', 'Inferior', 38, $event)"
                :disabled="isCheckedDisabled(38)"
              ></v-checkbox>
            </div>
          </div>

          <center>
            <v-img src="/img/dientes2/INF 16.png" width="50" height="100%" />
          </center>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="dientes_acciones"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:[`item.caras`]="{ item }">
            <ul>
              <li v-for="(cara, idx) in item.caras" :key="'c'+idx">{{buscarCara(cara)}}</li>
            </ul>
          </template>
          <template v-slot:[`item.diagnosticos`]="{ item }">
            <ul>
              <li v-for="(diagnostico, idx) in item.diagnosticos" :key="'d'+idx">{{buscarDiagnostico(diagnostico)}}</li>
            </ul>
          </template>
          <template v-slot:[`item.procedimientos`]="{ item }">
            <ul>
              <li v-for="(procedimiento, idx) in item.procedimientos" :key="'p'+idx">{{buscarProcedimeinto(procedimiento)}}</li>
            </ul>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip-icon
              icon="mdi-pencil"
              text="Editar"
              @click="editarDiente(item)"
            ></v-tooltip-icon>
            <v-tooltip-icon
              icon="mdi-trash-can-outline"
              text="Eliminar"
              @click="eliminarDiente(item)"
            ></v-tooltip-icon>
          </template> 
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VAlertDialog from "@/components/VAlertDialog.vue";
export default {
  components: {
    VAlertDialog,
  },
  data: () => ({
    caras: [
      { id_cara: 7, nombre: "N/A" },
      { id_cara: 1, nombre: "Vestibular" },
      { id_cara: 2, nombre: "Oclusal" },
      { id_cara: 3, nombre: "Distal" },
      { id_cara: 4, nombre: "Mesial" },
      { id_cara: 5, nombre: "Palatino" },
      { id_cara: 6, nombre: "Lingual" },
    ],
    diagnosticos: [
      { id_diagnostico: 14, nombre: "Diente Sano" },
      { id_diagnostico: 1, nombre: "Diente Ausente" },
      { id_diagnostico: 2, nombre: "Corona en buen estado" },
      { id_diagnostico: 3, nombre: "Obturación en buen estado" },
      { id_diagnostico: 4, nombre: "Sellante de fosas y fisuras en buen estado" },
      { id_diagnostico: 5, nombre: "Prótesis parcial fija en buen estado" },
      { id_diagnostico: 6, nombre: "Presencia de aparato de ortodoncia" },
      { id_diagnostico: 7, nombre: "Diente indicado a extracción" },
      { id_diagnostico: 8, nombre: "Corona en mal estado" },
      { id_diagnostico: 9, nombre: "Obturación en mal estado" },
      { id_diagnostico: 10, nombre: "Sellante de fosas y fisuras en mal estado" },
      { id_diagnostico: 11, nombre: "Prótesis parcial fija en mal estado" },
      { id_diagnostico: 12, nombre: "Fractura de corona dental" },
      { id_diagnostico: 13, nombre: "Caries dental (se marca la superficie afectada)" },
    ],
    procedimientos: [
      {
        id_procedimiento: 1,
        nombre: "Selladores de fosetas y fisuras dentales",
      },
      { id_procedimiento: 2, nombre: "Obturación con resinas" },
      { id_procedimiento: 3, nombre: "Obturación con ionómero de vidrio" },
      { id_procedimiento: 4, nombre: "Exodoncias" },
      /*
      { id_procedimiento: 5, nombre: "Eliminación de focos de infección" },
      {
        id_procedimiento: 6,
        nombre: "Detección y tratamiento pulpitis y necrosis pulpar",
      },
      */
    ],
    checkedNames: [],
    conversiones: [
      {
        imagen: "/img/dientes2/SUP 8.png",
        id_diente: 11,
      },
      {
        imagen: "/img/dientes2/SUP 7.png",
        id_diente: 12,
      },
      {
        imagen: "/img/dientes2/SUP 6.png",
        id_diente: 13,
      },
      {
        imagen: "/img/dientes2/SUP 5.png",
        id_diente: 14,
      },
      {
        imagen: "/img/dientes2/SUP 4.png",
        id_diente: 15,
      },
      {
        imagen: "/img/dientes2/SUP 3.png",
        id_diente: 16,
      },
      {
        imagen: "/img/dientes2/SUP 2.png",
        id_diente: 17,
      },
      {
        imagen: "/img/dientes2/SUP 1.png",
        id_diente: 18,
      },
      {
        imagen: "/img/dientes2/SUP 9.png",
        id_diente: 21,
      },
      {
        imagen: "/img/dientes2/SUP 10.png",
        id_diente: 22,
      },
      {
        imagen: "/img/dientes2/SUP 11.png",
        id_diente: 23,
      },
      {
        imagen: "/img/dientes2/SUP 12.png",
        id_diente: 24,
      },
      {
        imagen: "/img/dientes2/SUP 13.png",
        id_diente: 25,
      },
      {
        imagen: "/img/dientes2/SUP 14.png",
        id_diente: 26,
      },
      {
        imagen: "/img/dientes2/SUP 15.png",
        id_diente: 27,
      },
      {
        imagen: "/img/dientes2/SUP 16.png",
        id_diente: 28,
      },
      {
        imagen: "/img/infantil/SUP 5.png",
        id_diente: 51,
      },
      {
        imagen: "/img/infantil/SUP 4.png",
        id_diente: 52,
      },
      {
        imagen: "/img/infantil/SUP 3.png",
        id_diente: 53,
      },
      {
        imagen: "/img/infantil/SUP 2.png",
        id_diente: 54,
      },
      {
        imagen: "/img/infantil/SUP 1.png",
        id_diente: 55,
      },
      {
        imagen: "/img/infantil/SUP 7.png",
        id_diente: 61,
      },
      {
        imagen: "/img/infantil/SUP 8.png",
        id_diente: 62,
      },
      {
        imagen: "/img/infantil/SUP 9.png",
        id_diente: 63,
      },
      {
        imagen: "/img/infantil/SUP 9.png",
        id_diente: 64,
      },
      {
        imagen: "/img/infantil/SUP 10.png",
        id_diente: 65,
      },
      {
        id_diente: 81,
        imagen: "/img/infantil/INF 5.png",
      },
      {
        id_diente: 82,
        imagen: "/img/infantil/INF 4.png",
      },
      {
        id_diente: 83,
        imagen: "/img/infantil/INF 3.png",
      },
      {
        id_diente: 84,
        imagen: "/img/infantil/INF 2.png",
      },
      {
        id_diente: 85,
        imagen: "/img/infantil/INF 1.png",
      },
      {
        id_diente: 71,
        imagen: "/img/infantil/INF 6.png",
      },
      {
        id_diente: 72,
        imagen: "/img/infantil/INF 7.png",
      },
      {
        id_diente: 73,
        imagen: "/img/infantil/INF 8.png",
      },
      {
        id_diente: 74,
        imagen: "/img/infantil/INF 9.png",
      },
      {
        id_diente: 75,
        imagen: "/img/infantil/INF 10.png",
      },
      {
        id_diente: 41,
        imagen: "/img/dientes2/INF 8.png",
      },
      {
        id_diente: 42,
        imagen: "/img/dientes2/INF 7.png",
      },
      {
        id_diente: 43,
        imagen: "/img/dientes2/INF 6.png",
      },
      {
        id_diente: 44,
        imagen: "/img/dientes2/INF 5.png",
      },
      {
        id_diente: 45,
        imagen: "/img/dientes2/INF 4.png",
      },
      {
        id_diente: 46,
        imagen: "/img/dientes2/INF 3.png",
      },
      {
        id_diente: 47,
        imagen: "/img/dientes2/INF 2.png",
      },
      {
        id_diente: 48,
        imagen: "/img/dientes2/INF 1.png",
      },
      {
        id_diente: 31,
        imagen: "/img/dientes2/INF 9.png",
      },
      {
        id_diente: 32,
        imagen: "/img/dientes2/INF 10.png",
      },
      {
        id_diente: 33,
        imagen: "/img/dientes2/INF 11.png",
      },
      {
        id_diente: 34,
        imagen: "/img/dientes2/INF 12.png",
      },
      {
        id_diente: 35,
        imagen: "/img/dientes2/INF 13.png",
      },
      {
        id_diente: 36,
        imagen: "/img/dientes2/INF 14.png",
      },
      {
        id_diente: 37,
        imagen: "/img/dientes2/INF 15.png",
      },
      {
        id_diente: 38,
        imagen: "/img/dientes2/INF 16.png",
      },
    ],
    dialog: false,
    currentTooth: {
      tipo: null,
      posicion: null,
      numero: null,
      imagen: null,
      caras: [],
      diagnosticos: [],
      procedimientos: [],
    },
    defaultCurrentTooth: {
      tipo: null,
      posicion: null,
      numero: null,
      imagen: null,
      caras: [],
      diagnosticos: [],
      procedimientos: [],
    },
    dientes_acciones: [],
    copyItem: {
      tipo: null,
      posicion: null,
      numero: null,
      imagen: null,
      caras: [],
      diagnosticos: [],
      procedimientos: [],
    },
    headers: [
      {
        text: "Dentadura",
        value: "tipo",
        align: "center",
        sortable: false
      },
      {
        text: "Posición",
        value: "posicion",
        align: "center",
        sortable: false
      },
      {
        text: "Número de Órgano Dental",
        align: "center",
        sortable: false,
        value: "numero"
      },
      {
        text: "Cara del Órgano Dental",
        value: "caras",
        align: "center",
        sortable: false
      },
      {
        text: "Diagnóstico del Órgano Dental",
        value: "diagnosticos",
        align: "center",
        sortable: false
      },
      {
        text: "Procedimiento del Órgano Dental",
        value: "procedimientos",
        align: "center",
        sortable: false
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false
      },
    ],
  }),
  methods: {
    copiarLabel: (diente) =>
      `[${diente.numero}] - ${diente.tipo} - ${diente.posicion}`,
    openDialog(tipo, posicion, numero, evt) {
      if (evt.indexOf(`${numero}`) >= 0) {
        this.dialog = true;
        this.currentTooth = Object.assign({}, this.defaultCurrentTooth);
        this.currentTooth.caras = [];
        this.currentTooth.diagnosticos = [];
        this.currentTooth.procedimientos = [];
        this.currentTooth.tipo = tipo;
        this.currentTooth.posicion = posicion;
        this.currentTooth.numero = numero;

        var conv = this.conversiones.find((c) => c.id_diente == numero);
        if (conv !== undefined && conv != null)
          this.currentTooth.imagen = conv.imagen;

        //console.log(this.currentTooth);
      } else {
        this.dialog = false;
        this.currentTooth = Object.assign({}, this.defaultCurrentTooth);
        this.currentTooth.caras = [];
        this.currentTooth.diagnosticos = [];
        this.currentTooth.procedimientos = [];

        this.dientes_acciones = this.dientes_acciones.filter(
          (d) => d.numero != numero
        );
      }
    },
    isCheckedDisabled() {
      return this.dialog == true;
    },
    closeDialog(numero_diente = null) {
      if (numero_diente != null) {
        var idx = this.checkedNames.indexOf(`${numero_diente}`);
        if (idx >= 0) this.checkedNames.splice(idx, 1);
      }
      this.currentTooth = Object.assign({}, this.defaultCurrentTooth);
      this.currentTooth.caras = [];
      this.currentTooth.diagnosticos = [];
      this.dialog = false;
    },
    save() {
      if (this.currentTooth.caras.length == 0) {
        this.$refs.alert.setMessage(
          "",
          `Debe de indicar al menos una Cara del Órgano Dental.`
        );
        this.$refs.alert.open();
        return;
      }

      if (this.currentTooth.diagnosticos.length == 0) {
        this.$refs.alert.setMessage(
          "",
          `Debe de indicar al menos un Diagnóstico del Órgano Dental.`
        );
        this.$refs.alert.open();
        return;
      }

      if (this.currentTooth.procedimientos.length == 0) {
        this.$refs.alert.setMessage(
          "",
          `Debe de indicar al menos un Procedimiento del Órgano Dental.`
        );
        this.$refs.alert.open();
        return;
      }

      var idx_save = this.dientes_acciones.findIndex(x => x.numero == this.currentTooth.numero);

      if(idx_save >= 0) this.dientes_acciones[idx_save] = Object.assign({}, this.currentTooth);
      else this.dientes_acciones.push(Object.assign({}, this.currentTooth));

      this.closeDialog();
    },
    copiarDiente() {
      this.currentTooth.caras = [].concat(this.copyItem.caras);
      this.currentTooth.diagnosticos = [].concat(this.copyItem.diagnosticos);
      this.currentTooth.procedimientos = [].concat(
        this.copyItem.procedimientos
      );
      this.copyItem = Object.assign({}, this.defaultCurrentTooth);
    },
    buscarDiagnostico(id){
      
      var idx = this.diagnosticos.findIndex(x => x.id_diagnostico == id);
      //console.log('idx', idx);
      if(idx >= 0) return this.diagnosticos[idx].nombre;
      else return "";
    },
    buscarProcedimeinto(id){
      var idx = this.procedimientos.findIndex(x => x.id_procedimiento == id);
      if(idx >= 0) return this.procedimientos[idx].nombre;
      else return "";
    },
    buscarCara(id){
      var idx = this.caras.findIndex(x => x.id_cara == id);
      if(idx >= 0) return this.caras[idx].nombre;
      else return "";
    },
    eliminarDiente(item){
      this.checkedNames = this.checkedNames.filter(x => x != `${item.numero}`);
      this.dientes_acciones = this.dientes_acciones.filter(x => x != item);
    },
    editarDiente(item){
      var idx = this.dientes_acciones.findIndex(x => x.numero == item.numero);
      if(idx >= 0){
        this.currentTooth = Object.assign({}, this.defaultCurrentTooth);
        this.currentTooth = item;
        this.dialog = true;
      }
    },
    obtenerDatos(){
      if(this.dientes_acciones.length == 0) return null;

      return this.dientes_acciones;
    },
    restablecer(){
      this.dientes_acciones = [];
      this.checkedNames = [];
      this.currentTooth = Object.assign({}, this.defaultCurrentTooth);
      this.copyItem = Object.assign({}, this.defaultCurrentTooth);
      this.dialog = false;

    }
  },
};
</script>

<style scoped>
.mouth {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.check {
  display: flex;
  justify-content: center;
}

.button-container {
  margin: 0px;
}

.filled {
  background-color: #8c8c8c;
  color: #fff;
}

.check[data-v-078cecbc] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 20px;
}

@media (min-width: 960px) {
  .dientes .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 11%;
    float: right !important;
  }
}

@media (max-width: 760px) {
  .dientes .col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    float: left;
  }

  .dientes .col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 20% !important;
    flex: 20% !important;
    max-width: 20% !important;
    float: left;
  }
}
</style>
