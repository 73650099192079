<template>
  <v-tooltip bottom v-if="!enable">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-checkbox
          v-model="checkboxValue"
          :disabled="!enable"
          :label="`¿Agregar ${consulta}?`"
          :outlined="outlined"
          :dense="dense"
          :hide-details="hideDetails"
        ></v-checkbox>
      </div>
    </template>
    <span>Este paciente cuenta con una {{ consulta }} <span class="font-weight-bold">activa y pendiente.</span></span>
  </v-tooltip>

  <v-checkbox
    v-else
    v-model="checkboxValue"
    :label="`¿Agregar ${consulta}?`"
    :outlined="outlined"
    :dense="dense"
    :hide-details="hideDetails"
  ></v-checkbox>
</template>

<script>
export default {
  name: "ConsultaCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    consulta: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checkboxValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.checkboxValue = val;
    },
    checkboxValue(val) {
      this.$emit('input', val);
    },
  },
};
</script>
