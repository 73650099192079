<template>
  <v-col :cols="cols" :md="md" class="py-2">
    <v-col cols="12" class="d-flex align-center pa-0 ma-0">
      <v-check
        v-model="localErrorState"
        dense
        hide-details
        indeterminate
      >
      </v-check>
      <v-text-field
        :label="label"
        dense
        outlined
        hide-details
        v-model="localModel"
        :readonly="readonly"
        :error="localErrorState === false"
      ></v-text-field>
    </v-col>

    <v-col
      cols="12"
      v-if="localErrorState === false"
      class="ma-0 pa-0 py-2"
    >
      <v-textarea
        :label="textareaLabel"
        dense
        outlined
        hide-details
        v-model="localTextareaModel"
        auto-grow
        rows="1"
      ></v-textarea>
    </v-col>
  </v-col>
</template>

<script>
import VCheck from '@/components/VCheck.vue';

export default {
  name: "TextFieldWithCheckbox",
  components: {
    VCheck
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    errorState: {
      type: Boolean,
      default: null,
    },
    textareaLabel: {
      type: String,
      default: "Anomalía",
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    cols: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 12,
    },
  },
  emits: ['update:value', 'update:errorState', 'update:textareaModel'],
  computed: {
    localErrorState: {
      get() {
        return this.errorState;
      },
      set(value) {
        this.$emit('update:errorState', value);
      }
    },
    localModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      }
    },
    localTextareaModel: {
      get() {
        return "";
      },
      set(value) {
        this.$emit('update:textareaModel', value);
      }
    }
  }
};
</script>
