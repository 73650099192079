<template>
  <div>
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-row  class="pa-1 ma-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-text-field
          v-model="editedItem.escolaridad"
          label="Escolaridad"
          dense
          hide-details
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <br>
    <v-row class="pa-1 ma-0">
      <v-col cols="12" class="pa-0 ma-0 align-self-center">
        Nombre y parentesco del tutor:
      </v-col>
      <v-col cols="3" class="ma-0 pa-0 pr-2">
        <v-text-field
          v-model="editedItem.tutor_apellido_paterno"
          label="Apellido Paterno"
          outlined
          hide-details
          dense
        >
        </v-text-field>
      </v-col>
      <v-col cols="3" class="ma-0 pa-0 px-2">
        <v-text-field
          v-model="editedItem.tutor_apellido_materno"
          label="Apellido Materno"
          outlined
          hide-details
          dense
        >
        </v-text-field>
      </v-col>
      <v-col cols="3" class="ma-0 pa-0 px-2">
        <v-text-field
          v-model="editedItem.tutor_nombre"
          label="Nombre(s)"
          outlined
          hide-details
          dense
        >
        </v-text-field>
      </v-col>
      <v-col cols="3" class="ma-0 pa-0 pl-2">
        <v-autocomplete
          v-model="editedItem.tutor_sexo"
          :items="generos"
          item-value="id_genero"
          item-text="nombre"
          label="Sexo"
          outlined
          dense
          hide-details
        >
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VAlertDialog from '@/components/VAlertDialog.vue'

export default {
  components: {
    VAlertDialog
  },
  data: () => ({
    dialog: false,
    editedItem: {
      escolaridad: null,
      tutor_apellido_paterno: null,
      tutor_apellido_materno: null,
      tutor_nombre: null,
      tutor_sexo: null,
    },
    defaultEditedItem: {
      escolaridad: null,
      tutor_apellido_paterno: null,
      tutor_apellido_materno: null,
      tutor_nombre: null,
      tutor_sexo: null,
    },
    validaciones: [
      { nombre: 'Escolaridad', campo: 'escolaridad', tipo: 'string'},
      { nombre: 'Tutor - Apellido Paterno', campo: 'tutor_apellido_paterno', tipo: 'string' },
      { nombre: 'Tutor - Apellido Materno', campo: 'tutor_apellido_materno', tipo: 'string' },
      { nombre: 'Tutor - Nombre', campo: 'tutor_nombre', tipo: 'string' },
      { nombre: 'Tutor - Sexo', campo: 'tutor_nombre', tipo: 'bool' }
    ],
    generos: [
      {
        id_genero: false,
        nombre: "Femenino"
      },
      {
        id_genero: true,
        nombre: "Masculino"
      }
    ],
  }),
  methods: {
    obtenerDatos(){
      var valido = true;
      for (let i = 0; i < this.validaciones.length; i++) {
        if(this.editedItem[this.validaciones[i].campo] == null || (this.validaciones[i].tipo == 'string' && this.editedItem[this.validaciones[i].campo].length < 0)){
          valido = false;
          this.$refs.alert.setMessage("", `El Campo ${this.validaciones[i].nombre} es Obligatorio`);
          this.$refs.alert.open();
          break;
        }

        if(this.validaciones[i].tipo == 'boolean' && this.validaciones[i].nota == true && this.editedItem[this.validaciones[i].campo] == this.validaciones[i].valor_nota && ( this.editedItem[this.validaciones[i].campo+'_nota'] == null ||  this.editedItem[this.validaciones[i].campo+'_nota'].length < 0    )){
          valido = false;
          this.$refs.alert.setMessage("", `La Nota del Campo ${this.validaciones[i].nombre} es Obligatoria`);
          this.$refs.alert.open();
          break;
        }
      }

      return (valido) ? this.editedItem : null;
    },
    restablecer(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
    }
  }
}
</script>