<template>
  <v-row class="pa-0 ma-0">
    <v-col
      v-if="label != null"
      cols="12" class="py-0 px-0 ma-0 text-caption">
      {{ label }}
    </v-col>
    <v-col cols="12" class="pt-0 mt-0 pl-0">
      <date-picker
        :disabled-date="notAfterToday"
        :value="internalValue"
        @input="updateValue"
        type="date"
        valueType="format"
        format="DD-MM-YYYY"
        placeholder="Fecha de Nacimiento"
      ></date-picker>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

export default {
  components: {
    DatePicker
  },
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      internalValue: this.value ? moment(this.value, 'YYYY-MM-DD').format('DD-MM-YYYY') : null
    };
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue ? moment(newValue, 'YYYY-MM-DD').format('DD-MM-YYYY') : null;
    }
  },
  methods: {
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    updateValue(newValue) {
      const formattedValue = moment(newValue, 'DD-MM-YYYY').format('YYYY-MM-DD');
      this.$emit('input', formattedValue);
    }
  }
}
</script>

<style scoped>
.mx-datepicker {
  max-width: 380px !important;
}

.mx-datepicker-body {
  max-width: 380px !important;
}
</style>