<template>
  <div class="pa-3 ma-3">
    <v-alert-dialog ref="alert"></v-alert-dialog>
    <v-dialog max-width="65%" v-model="dialogAddEdit" persistent>
      <v-card>
        <v-card-title>
          {{ editedItem.id_almacen_tipo_movimiento === null ? "Crear" : "Editar" }} Tipo de Movimiento
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="editedItem.codigo" label="Código" outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="editedItem.nombre" label="Nombre" outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="65%" v-model="dialogDelete" persistent>
      <v-card>
        <v-card-title>Eliminar Tipo de Movimiento</v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar el tipo de movimiento llamado "{{ editedItem.nombre }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="openDialogAddEdit(null)">Crear Tipo de Movimiento</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table :items="items" :headers="headers" :loading="loading">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="openDialogAddEdit(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="openDialogDelete(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import VAlertDialog from '@/components/VAlertDialog.vue';

export default {
  components: {
    VAlertDialog
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers: [
      { text: "Código", value: "codigo", align: "center", width: "15%" },
      { text: "Tipo de Movimiento", value: "nombre", align: "center", width: "75%" },
      { text: "Acciones", value: "actions", align: "center", width: "10%" }
    ],
    editedItem: {
      id_almacen_tipo_movimiento: null,
      nombre: "",
    },
    defaultEditedItem: {
      id_almacen_tipo_movimiento: null,
      nombre: "",
    },
    loadingSave: false,
    dialogDelete: false,
    loadingDelete: false
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      axios.get('/Almacenes/ListarTiposMovimiento')
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    openDialogAddEdit(editItem = null) {
      if (editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit() {
      if (this.editedItem.codigo == null || this.editedItem.codigo.length === 0 || this.editedItem.codigo.length > 8) {
        this.$refs.alert.setMessage("", "Debe de Ingresar un Código entre 1 y 8 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.nombre == null || this.editedItem.nombre.length === 0 || this.editedItem.nombre.length > 256) {
        this.$refs.alert.setMessage("", "Debe de Ingresar un Nombre entre 1 y 256 caracteres");
        this.$refs.alert.open();
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_almacen_tipo_movimiento === null ? 'POST' : 'PUT',
        url: this.editedItem.id_almacen_tipo_movimiento === null ? '/Almacenes/CrearTipoMovimiento' : `/Almacenes/EditarTipoMovimiento/${this.editedItem.id_almacen_tipo_movimiento}`,
        data: {
          codigo: this.editedItem.codigo,
          nombre: this.editedItem.nombre
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        if (error.response.status == 409) {
          this.$refs.alert.setMessage("", "Existe un Tipo de Movimiento con el mismo Código.");
          this.$refs.alert.open();
          return;
        }
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete() {
      this.loadingDelete = true;
      axios.delete(`/Almacenes/EliminarTipoMovimiento/${this.editedItem.id_almacen_tipo_movimiento}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    }
  }
}
</script>