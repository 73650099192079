<template>
  <div class="pa-2">
    <v-icon
      :color="value === true ? 'green' : 'grey'"
      class="mr-1"
      @click="toggleState(true)"
    >
      mdi-check
    </v-icon>
    <v-icon
      :color="value === false ? 'red' : 'grey'"
      class="mx-1"
      @click="toggleState(false)"
    >
      mdi-close
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "BooleanToggle",
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    toggleState(newValue) {
      if (this.value !== newValue) {
        this.$emit("input", newValue); // Emite el nuevo valor
      } else {
        this.$emit("input", null); // Resetea a null si es el mismo
      }
    },
  },
};
</script>

<style scoped>
.v-icon {
  cursor: pointer;
  font-size: 32px;
}
</style>
