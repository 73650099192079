<template>
  <v-container style="max-width: 1600px !important;">
     <v-confirm-dialog
      @accept="acceptDelete"
      ref="dialog"
    ></v-confirm-dialog>
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      v-model="dialogDoctor"
      max-width="80%"
      persistent
    >
      <v-card>
        <v-card-title>
          Datos del Doctor
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="doctor.apellido_paterno"
                label="Apellido Paterno"
                dense
                hide-details
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="doctor.apellido_materno"
                label="Apellido Materno"
                dense
                hide-details
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="doctor.nombre"
                label="Nombre"
                dense
                hide-details
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="doctor.cedula"
                label="Cédula Profesional"
                dense
                hide-details
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="doctor.institucion"
                label="Institución"
                dense
                hide-details
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" v-if="(doctor.id_doctor == null || nuevaFirma == true)">
              <v-card>
                <v-card-text>
                  <TopazSignature
                    ref="doctor"
                    id="doctor"
                  ></TopazSignature>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  Firma del Doctor
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              v-else
              cols="12"
            >
              <v-img
                :src="doctor.firma"
                contain
              ></v-img>
              <v-btn
                color="blue"
                @click="nuevaFirma = true"
                text
              >
                Nueva Firma
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="cancelarDoctor"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="guardarDialogDoctor"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      max-width="80%"
      persistent
    >
      <v-card>
        <v-card-title>
          {{editedItem.id_usuario == null ? "Nuevo" : "Editar"}} Usuario
        </v-card-title>
        <v-card-text>
          <v-form v-model="isValid">
            <v-row>
              <v-col cols="6" md="6" sm="6" v-if="editedItem.id_usuario == null">
                <v-text-field
                  v-model="editedItem.username"
                  label="Nombre del Usuario"
                  outlined
                  :rules="[rules.required, rules.min6]"
                  :error="firstTime[0]"
                  @change="used(0)"
                  @input="used(0)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="6" sm="6" v-if="editedItem.id_usuario == null">
                <v-text-field
                  v-model="editedItem.password"
                  label="Contraseña"
                  outlined
                  :rules="[rules.required, rules.min8]"
                  :error="firstTime[1]"
                  @change="used(1)"
                  @input="used(1)"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" md="4" sm="4">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Nombre de la Persona"
                  outlined
                  :rules="[rules.required]"
                  :error="firstTime[3]"
                  @change="used(3)"
                  @input="used(3)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" md="4" sm="4">
                <v-text-field
                  v-model="editedItem.apellido_paterno"
                  label="Apellido Paterno de la Persona"
                  outlined
                  :rules="[rules.required]"
                  :error="firstTime[4]"
                  @change="used(4)"
                  @input="used(4)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" md="4" sm="4">
                <v-text-field
                  v-model="editedItem.apellido_materno"
                  label="Apellido Materno de la Persona"
                  outlined
                  :rules="[rules.required]"
                  :error="firstTime[5]"
                  @change="used(5)"
                  @input="used(5)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="editedItem.ids_roles"
                  label="Rol(es)"
                  :items="roles"
                  item-text="name"
                  item-value="id_rol"
                  outlined
                  multiple
                  :error="editedItem.ids_roles == null || editedItem.ids_roles.length <= 0"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" md="6" sm="6">
                <v-autocomplete
                  v-model="editedItem.ids_unidades_medicas"
                  label="Unidad(es) Médica(s)"
                  :items="unidades_medicas"
                  :item-text="codigoNombre"
                  item-value="id_unidad_medica"
                  outlined
                  multiple
                  @change="resetUnidadMedicaActiva"
                  :error="editedItem.ids_unidades_medicas == null || editedItem.ids_unidades_medicas.length <= 0"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" md="6" sm="6">
                <v-autocomplete
                  v-model="editedItem.id_unidad_medica_activa"
                  label="Unidad Médica Activa"
                  :items="unidadesMedicasFiltradas()"
                  :item-text="codigoNombre"
                  item-value="id_unidad_medica"
                  outlined
                  :error="editedItem.id_unidad_medica_activa == null || editedItem.id_unidad_medica_activa.length <= 0"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="editedItem.id_usuario != null">
                <v-switch
                  v-model="editedItem.activo"
                  :label="editedItem.activo ? 'Activo': 'Deshabilitado'"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="reset">Cancelar</v-btn>
          <v-btn-save label="Guardar" text @click="save" :saving="saving"></v-btn-save>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPassword"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          Cambiar Contraseña
        </v-card-title>
        <v-card-text>
          <v-form v-model="isValidPassword">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="password"
                  label="Contraseña"
                  outlined
                  :rules="[rules.required, rules.min8]"
                  :error="firstTime[2]"
                  @change="used(2)"
                  @input="used(2)"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="resetPassword">Cancelar</v-btn>
          <v-btn-save label="Guardar" text @click="savePassword" :saving="savingPassword"></v-btn-save>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn color="primary" @click="dialog = true">Nuevo Usuario</v-btn>
    <br>
    <br>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <br>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
    >
      <template v-slot:[`item.roles`]="{ item }">
        <ul>
          <li
            v-for="(rol, idx) in item.roles"
            :key="`rol-${idx}`"
          >
            {{rol}}
          </li>
        </ul>
      </template>
      <template v-slot:[`item.activo`]="{ item }">
        {{item.activo ? 'Activo': 'Deshabilitado'}}
      </template>
      <template v-slot:[`item.full_name`]="{ item }">
        {{item.nombre}} {{item.apellido_paterno}} {{item.apellido_materno}}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip-icon
          icon="mdi-key"
          text="Cambiar Contraseña"
          @click="changePassword(item)"
        ></v-tooltip-icon>
        <v-tooltip-icon
          icon="mdi-pencil-outline"
          text="Editar"
          @click="editItem(item)"
        ></v-tooltip-icon>
        <v-tooltip-icon
          icon="mdi-trash-can-outline"
          text="Eliminar"
          @click="deleteItem(item)"
        ></v-tooltip-icon>
        <v-tooltip-icon
          v-if="item.ids_roles.indexOf('485c279b-a43f-4a7c-894d-94cac1c30799') >= 0"
          icon="mdi-card-account-details-outline"
          text="Datos del Doctor"
          @click="abrirDialogDoctor(item.id_usuario)"
        >
        </v-tooltip-icon>
      </template> 
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import VBtnSave from "@/components/VBtnSave";
import VTooltipIcon from "@/components/VTooltipIcon";
import VConfirmDialog from "@/components/VConfirmDialog";
//import vueSignature from "vue-signature";
import TopazSignature from '@/components/TopazSignature.vue';

export default {
  components:{
    //vueSignature,
    TopazSignature,
    VBtnSave,
    VTooltipIcon,
    VConfirmDialog
  },
  data:() => ({
    rules: {
      required: v => !!v || 'Obligatorio.',
      min: v => v.length >= 2 || 'Mínimo 2 Caracteres',
      min6: v => v.length >= 6 || 'Mínimo 6 Caracteres',
      min8: v => v.length >= 8 || 'Mínimo 8 Caracteres',
    },
    firstTime:[true,true,true,true,true,true],
    dialog:false,
    users:[],
    headers:[
      { 
        text: "Nombre del Usuario",
        align: "left",
        sortable: true,
        value: "username",
      },
      {
        text: "Nombre de la Persona",
        align: "left",
        sortable: true,
        value: "full_name",
      },
      { 
        text: "Rol(es)",
        align: "left",
        sortable: true,
        value: "roles",
      },
      { 
        text: "Estado",
        align: "left",
        sortable: true,
        value: "activo",
      },
      { 
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
    isValid:false,
    editedItem:{
      id_usuario: null,
      ids_roles:[],
      roles: [],
      activo: true,
      username:"",
      password:"",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      id_unidad_medica_activa: null,
      ids_unidades_medicas: []

    },
    defaultItem:{
      id_usuario: null,
      ids_roles:[],
      roles: [],
      activo: true,
      username:"",
      password:"",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      id_unidad_medica_activa: null,
      ids_unidades_medicas: []
    },
    saving: false,
    loading: false,
    id_usuario: null,
    password:"",
    dialogPassword:false,
    savingPassword:false,
    isValidPassword:false,
    showPassword:false,
    roles: [],
    dialogDoctor: false,
    doctor: {
      id_doctor: null,
      id_usuario: null,
      apellido_paterno: null,
      apellido_materno: null,
      nombre: null,
      cedula: null,
      institucion: null,
      firma: null,
    },
    defaultDoctor: {
      id_doctor: null,
      id_usuario: null,
      apellido_paterno: null,
      apellido_materno: null,
      nombre: null,
      cedula: null,
      institucion: null,
      firma: null,
    },
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    nuevaFirma: false,
    loadingUnidadesMedicas: false,
    unidades_medicas: [],
    search: ''
  }),
  mounted(){
    this.getUsers();
    this.getRoles();
    this.getUnidadesMedicas();
  },
  methods:{
    getItems(){
      this.getRoles();
      this.getUsers();
      this.getUnidadesMedicas();
    },
    codigoNombre: item => `[${item.codigo}] - ${item.nombre}`,
    unidadesMedicasFiltradas(){
      return this.unidades_medicas.filter(x => this.editedItem.ids_unidades_medicas.indexOf(x.id_unidad_medica) >= 0);
    },
    resetUnidadMedicaActiva(){
      this.editedItem.id_unidad_medica_activa = null;
    },
    initialize(){
      this.getUsers();
    },
    used(pos){
      this.firstTime[pos] = false;
    },
    getUsers(){
      this.loading = true;

      axios
      .get("/Users/List")
      .then(response => {
        this.users = response.data;
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    getRoles(){
      axios
      .get("/Roles/ListarRoles")
      .then(response => {
        this.roles = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getUnidadesMedicas(){
      this.loadingUnidadesMedicas = true;
      axios
      .get('/Catalogos/ListarUnidadesMedicas')
      .then(response => {
        this.unidades_medicas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingUnidadesMedicas = false;
      })
    },
    reset(){
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = false;
      this.firstTime = [true,true,true,true,true,true];
      this.showPassword = false;
    },
    editItem(item){
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.firstTime = [false,false,false,false,false,false];
    },
    deleteItem(item){
      this.id_usuario = item.id_usuario;
      this.$refs.dialog.setMessage("Confirmación de Borrado","¿Esta seguro que desea eliminar este registro? Esta acción es permanente y no se puede deshacer.");
      this.$refs.dialog.setText("Aceptar", "Cancelar");
      this.$refs.dialog.open();
    },
    changePassword(item){
      this.id_usuario = item.id_usuario;
      this.firstTime = [true,true,true,true,true,true];
      this.dialogPassword = true;
    },
    savePassword(){
      if(!this.isValidPassword) return;

      this.savingPassword = true;
      axios
      .put("/Users/UpdatePassword",{
        id_usuario: this.id_usuario,
        password: this.password,
      })
      .then(() => {
        this.resetPassword();
      })
      .catch(error => {
        this.savingPassword = false;
        console.log(error);
      })
    },
    resetPassword(){
      this.id_usuario = null;
      this.password = "";
      this.dialogPassword = false;
      this.savingPassword = false;
      this.showPassword = false;
    },
    acceptDelete(){
      axios
      .delete("/Users/Delete/"+this.id_usuario)
      .then(() => {
        this.getUsers();
      })
      .catch(error => {
        console.log(error);
      });
    },
    save(){
      if(!this.isValid) return;

      this.saving = true;

      if(this.editedItem.id_usuario == null){
        axios
        .post("/Users/Create",{
          username: this.editedItem.username,
          password: this.editedItem.password,
          ids_roles: this.editedItem.ids_roles,
          nombre: this.editedItem.nombre,
          apellido_paterno: this.editedItem.apellido_paterno,
          apellido_materno: this.editedItem.apellido_materno,
          id_unidad_medica_activa: this.editedItem.id_unidad_medica_activa,
          ids_unidades_medicas: this.editedItem.ids_unidades_medicas
        })
        .then(() => {
          this.saving = false;
          this.reset();
          this.getUsers();
        })
        .catch(error => {
          this.saving = false;
          console.log(error);
        });
      }
      else{
        axios
        .put(`/Users/Update/${this.editedItem.id_usuario}`,{
          ids_roles: this.editedItem.ids_roles,
          activo: this.editedItem.activo,
          nombre: this.editedItem.nombre,
          apellido_paterno: this.editedItem.apellido_paterno,
          apellido_materno: this.editedItem.apellido_materno,
          id_unidad_medica_activa: this.editedItem.id_unidad_medica_activa,
          ids_unidades_medicas: this.editedItem.ids_unidades_medicas
        })
        .then(() => {
          this.saving = false;
          this.reset();
          this.getUsers();
        })
        .catch(error => {
          this.saving = false;
          console.log(error);
        });
      }
    },
    cancelarDoctor(){
      this.doctor = Object.assign({}, this.defaultDoctor);
      this.dialogDoctor = false;
      this.$refs.doctor.clear();
      this.nuevaFirma = false;
    },
    abrirDialogDoctor(id_usuario){
      axios
      .get(`/Doctores/ObtenerDatos/${id_usuario}`)
      .then(response => {
        if(response.data == null || response.data == "") {
          this.doctor = Object.assign({}, this.defaultDoctor);
          this.doctor.id_usuario = id_usuario;
        }
        else this.doctor = response.data;
        this.dialogDoctor = true;
      })
      .catch(error => {
        console.log(error)
      })
    },
    guardarDialogDoctor(){
      if (this.doctor.apellido_paterno == null || this.doctor.apellido_paterno.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar el Apellido Paterno del Doctor");
        this.$refs.alert.open();
        return;
      }

      if (this.doctor.apellido_materno == null || this.doctor.apellido_materno.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar el Apellido Materno del Doctor");
        this.$refs.alert.open();
        return;
      }

      if (this.doctor.nombre == null || this.doctor.nombre.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar el Nombre del Doctor");
        this.$refs.alert.open();
        return;
      }

      if (this.doctor.cedula == null || this.doctor.cedula.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar la Cédula del Doctor");
        this.$refs.alert.open();
        return;
      }


      if (this.doctor.institucion == null || this.doctor.institucion.length <= 0) {
        this.$refs.alert.setMessage("", "Debe ingresar la Institución de Procedencia del Doctor");
        this.$refs.alert.open();
        return;
      }

      if ((this.doctor.id_doctor == null ||this.nuevaFirma == true) && this.$refs.doctor.isEmpty()) {
        this.$refs.alert.setMessage("", "Debe ingresar una Firma.");
        this.$refs.alert.open();
        return;
      }

      axios
      .put(`/Doctores/ActualizarDatos`,{
        id_usuario: this.doctor.id_usuario,
        apellido_paterno: this.doctor.apellido_paterno,
        apellido_materno: this.doctor.apellido_materno,
        nombre: this.doctor.nombre,
        cedula: this.doctor.cedula,
        institucion: this.doctor.institucion,
        firma: (this.doctor.id_doctor == null ||this.nuevaFirma == true) ? this.$refs.doctor.save() : null
      })
      .then(() => {
        this.cancelarDoctor();
      })
      .catch(error => {
        console.log(error);
      })
    }
  }
}
</script>