// https://vuex.vuejs.org/en/mutations.html

export default {
  auth_request (state) {
    state.authStatus = 'loading'
  },
  auth_success (state, { token, user, ids_roles, ids_vistas, ids_unidades_medicas, id_unidad_medica_activa, nombre, apellido_paterno, apellido_materno, es_medico, configuracion_doctor, es_admin }) {
    state.authStatus = 'success'
    state.token = token
    state.user = user
    state.ids_roles = ids_roles
    state.ids_vistas = ids_vistas
    state.ids_unidades_medicas = ids_unidades_medicas
    state.id_unidad_medica_activa = id_unidad_medica_activa
    state.nombre = nombre
    state.apellido_paterno = apellido_paterno
    state.apellido_materno = apellido_materno
    state.es_medico = es_medico
    state.configuracion_doctor = configuracion_doctor
    state.es_admin = es_admin
  },
  auth_refresh (state, { token, ids_roles, ids_vistas, ids_unidades_medicas, id_unidad_medica_activa, nombre, apellido_paterno, apellido_materno, es_medico, configuracion_doctor, es_admin }) {
    state.authStatus = 'success'
    state.token = token
    state.ids_roles = ids_roles
    state.ids_vistas = ids_vistas
    state.ids_unidades_medicas = ids_unidades_medicas
    state.id_unidad_medica_activa = id_unidad_medica_activa
    state.nombre = nombre
    state.apellido_paterno = apellido_paterno
    state.apellido_materno = apellido_materno
    state.es_medico = es_medico
    state.configuracion_doctor = configuracion_doctor
    state.es_admin = es_admin
  },
  auth_error (state) {
    state.authStatus = 'error'
  },
  logout (state) {
    state.authStatus = ''
    state.token = ''
    state.user = ''
    state.ids_roles = []
    state.ids_vistas = []
    state.ids_unidades_medicas = []
    state.id_unidad_medica_activa = ''
    state.nombre = ''
    state.apellido_paterno = ''
    state.apellido_materno = ''
    state.es_medico = false
    state.configuracion_doctor = false
    state.es_admin = false
  },
  extemporaneo (state, { extemporaneo }){
    state.extemporaneo = extemporaneo
  }
}
