<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="65%"
  >
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-card>
      <v-card-title>
        Cancelar Consulta
      </v-card-title>

      <v-card-text>
        ¿Desea cancelar la consulta de {{cancelItem.nombre}} {{cancelItem.apellido_paterno}} {{cancelItem.apellido_materno}} con folio {{cancelItem.folio}}?
        <br>
        <br>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="id_motivo_cancelacion"
              outlined
              dense
              hide-details
              label="Motivo de Cancelación"
              :items="motivos_cancelacion"
              item-text="nombre"
              item-value="id_cancelacion_motivo"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="green"
          class="white--text"
          @click="closeDialog"
        >
          Cancelar
        </v-btn>
        <v-btn
          text
          color="red"
          class="white--text"
          @click="save"
          :loading="loading"
        >
          Cancelar Consulta
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import VAlertDialog from '@/components/VAlertDialog.vue'

export default {
  components:{
    VAlertDialog,
  },
  props: {
    url:{
      type: String,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    cancelItem: {
      id_paciente_servicio: null,
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      folio: "",
      sexo: ""
    },
    defaultCancelItem: {
      id_paciente_servicio: null,
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      folio: "",
      sexo: ""
    },
    id_motivo_cancelacion: null,
    motivos_cancelacion: [],
    loading: false
  }),
  mounted() {
    this.getMotivosCancelacion();
  },
  methods: {
    openDialog(item){
      this.dialog = true;
      this.cancelItem = item;
    },
    closeDialog(){
      this.dialog = false;
      this.cancelItem = Object.assign({}, this.defaultCancelItem);
      this.id_motivo_cancelacion = null;
    },
    getMotivosCancelacion(){
      this.loading = true;
      axios
        .get('/Catalogos/ListarMotivosCancelacion')
        .then(response => {
          this.motivos_cancelacion = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
        })
    },
    save(){
      if(this.id_motivo_cancelacion == null){
        this.$refs.alert.setMessage("", "Debe de seleccionar al menos un Motivo de Cancelación");
        this.$refs.alert.open();
        return;
      }

      this.loading = true;
      axios
        .post(`${this.url}/${this.cancelItem.id_paciente_servicio}/${this.id_motivo_cancelacion}`)
        .then(() => {
          this.closeDialog();
          this.$emit('change', null);
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>