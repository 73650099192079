<template>
  <div>
    <apexchart
      v-if="show"
      :width="width"
      :height="height"
      type="line"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div
      v-else
      class="text-center"
    >
      <v-progress-circular
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <br>
      <br>
      <div class="text-h6">Cargando</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: "250px"
    }
  },
  methods: {
    setData(series, categories){
      this.show = false;
      this.series = [];
      this.series = series;
      this.chartOptions = Object.assign({}, this.defaultChartOptions);
      this.chartOptions.xaxis.categories = categories;

      setTimeout(() => {
        this.show = true;
      }, 250); 
    }
  },
  data: () => ({
    show: false,
    series: [],
    chartOptions: {
      chart: {
        height: '250px',
        type: "line",
      },

      xaxis: {
        categories: [],
      },
    },
    defaultChartOptions: {
      chart: {
        height: '250px',
        type: "line",
      },

      xaxis: {
        categories: [],
      },
    },
  }),
}
</script>