<template>
  <v-dialog
    v-model="dialog"
    max-width="90%"
    persistent
  >
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-card>
      <v-card-title>
        Referencia Medica
      </v-card-title>
      <v-card-text>
        <br>
        <v-row>
          <v-autocomplete
            v-model="editedItem.urgencia"
            label="Emergencia"
            :items="respuestas"
            item-value="valor"
            item-text="texto"
            dense
            hide-details
            outlined
          >
          </v-autocomplete>
        </v-row>
        <br>
        <br>
        <v-row>
          <v-col cols="12" class="text-subtitle-1 grey lighten-3 grey--text text--darken-4">
            Datos del Paciente
          </v-col>
        </v-row>
        <br>
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <v-text-field
              v-model="editedItem.domicilio"
              label="Domicilio:"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="editedItem.municipo_localidad"
              label="Municipio, localidad y estado:"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="editedItem.telefono"
              label="Teléfono:"
              outlined
              dense
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Nombre del familiar o tutor que recibe y/o autoriza
            <br>
            <span class="text-caption">
              (en caso necesario)
            </span>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0 pr-2">
            <v-text-field
              v-model="editedItem.tutor_apellido_paterno"
              label="Apellido Paterno"
              outlined
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0 px-2">
            <v-text-field
              v-model="editedItem.tutor_apellido_materno"
              label="Apellido Materno"
              outlined
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0 pl-2">
            <v-text-field
              v-model="editedItem.tutor_nombre"
              label="Nombre(s)"
              outlined
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="text-subtitle-1 grey lighten-3 grey--text text--darken-4">
            Datos de unidad que refiere
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="editedItem.unidad_refiere"
              label="Unidad a la que se refiere"
              outlined
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="editedItem.servicio"
              label="Servicio"
              outlined
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="editedItem.estado"
              :items="estados"
              outlined
              dense
              hide-details
              label="Estado"
              :item-text="nombreEstado"
              item-value="id_estado"
              :loading="loadingEstados"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="editedItem.domicilio_refiere"
              label="Domicilio"
              outlined
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <br>
        <v-row class="pa-1 ma-0">
          <v-col cols="3" class="pa-0 ma-0">
            Nombre y cargo de quien acepta la transferencia
          </v-col>
          <v-col cols="3" class="ma-0 pa-0 pr-2">
            <v-text-field
              v-model="editedItem.receptor_apellido_paterno"
              label="Apellido Paterno"
              outlined
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0 px-2">
            <v-text-field
              v-model="editedItem.receptor_apellido_materno"
              label="Apellido Materno"
              outlined
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0 pl-2">
            <v-text-field
              v-model="editedItem.receptor_nombre"
              label="Nombre(s)"
              outlined
              hide-details
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="editedItem.resumen_clinico"
              label="Resumen Clínico"
              outlined
              dense
              hide-details
              rows="3"
              auto-grow
            >
            </v-textarea>
          </v-col>
          <v-col cols="12">
            <!--
            <v-textarea
              v-model="editedItem.auxiliares_diagnostico"
              label="Auxiliares de diagóstico practicados"
              outlined
              dense
              hide-details
              rows="3"
              auto-grow
            >
            </v-textarea>
            -->
            <span class="font-weight-bold">Auxiliares de diagóstico practicados</span>
            <v-data-table
              :headers="headersAuxiliaresDiagnosticos"
              :items="editedItem.auxiliares_diagnosticos"
              :items-per-page="-1"
              hide-default-footer
              hide-default-header
              no-data-text="No se han indicado Auxiliares de Diagóstico Practicados"
            >
              <template v-slot:[`item.tipo`]="{ item }">
                <v-text-field
                  v-model="item.tipo"
                  label="Tipo"
                  dense
                  hide-details
                  outlined
                >
                </v-text-field>
              </template>

              <template v-slot:[`item.resultado`]="{ item }">
                <v-textarea
                  v-model="item.resultado"
                  label="Resultado"
                  auto-grow
                  rows="1"
                  dense
                  hide-details
                  outlined
                >
                </v-textarea>
              </template>

              <template v-slot:[`item.fecha`]="{ item }">
                <v-text-field
                  v-model="item.fecha"
                  label="Fecha"
                  dense
                  hide-details
                  outlined
                >
                </v-text-field>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip-icon
                  icon="mdi-trash-can-outline"
                  text="Eliminar"
                  @click="eliminarAuxDiagnostico(item)"
                ></v-tooltip-icon>
              </template> 
            </v-data-table>
            <v-btn
              color="primary"
              @click="agregarAuxDiagnostico"
              text
            >
              Agregar Auxiliar de Diagnóstico
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="editedItem.diagnostico_envio"
              label="Diagnóstico(s) de envío "
              outlined
              dense
              hide-details
              rows="3"
              auto-grow
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <v-card>
              <v-container>
                <TopazSignature
                  ref="paciente"
                  id="paciente"
                ></TopazSignature>
                <v-text-field
                  v-model="editedItem.nombre_responsable"
                  label="Nombre Completo del Paciente y/o familiar, tutor"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-container>
            </v-card>
            <v-col cols="4"></v-col>
          </v-col>
        </v-row>
        
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          text
          @click="cancelar"
        >
          Restablecer
        </v-btn>
        <v-btn
          color="red"
          text
          @click="finalizar"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
//import vueSignature from "vue-signature";
import TopazSignature from '@/components/TopazSignature.vue';
import { mapState } from 'vuex'

export default {
  components: {
    //vueSignature
    TopazSignature
  },
  computed: {
    ...mapState(['extemporaneo']),
  },
  data: () => ({
    headersAuxiliaresDiagnosticos: [
      {
        text: "Tipo",
        value: "tipo",
        align: "center",
        sortable: false
      },
      {
        text: "Resultado",
        value: "resultado",
        align: "center",
        sortable: false
      },
      {
        text: "Fecha",
        value: "fecha",
        align: "center",
        sortable: false
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%",
        sortable: false
      }
    ],
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    dialog: false,
    loadingEstados: false,
    estados: [],
    respuestas: [
      {
        valor: true,
        texto: "Si"
      },
      {
        valor: false,
        texto: "No"
      }
    ],
    validaciones: [
      { nombre: 'Urgencia', campo: 'urgencia', tipo: 'boolean' },
      { nombre: 'Datos del Paciente - Domicilio', campo: 'domicilio', tipo: 'string' },
      { nombre: 'Datos del Paciente - Municipio, Localidad y Estado', campo: 'municipo_localidad', tipo: 'string' },
      { nombre: 'Datos del Paciente - Teléfono', campo: 'telefono', tipo: 'string' },
      /*
      { nombre: '', campo: 'tutor_apellido_paterno', tipo: 'string' },
      { nombre: '', campo: 'tutor_apellido_materno', tipo: 'string' },
      { nombre: '', campo: 'tutor_nombre', tipo: 'string' },
      */
      { nombre: 'Datos de unidad que refiere - Unidad a la que se refiere', campo: 'unidad_refiere', tipo: 'string' },
      { nombre: 'Datos de unidad que refiere - Servicio', campo: 'servicio', tipo: 'string' },
      { nombre: 'Datos de unidad que refiere - Estado', campo: 'estado', tipo: 'string' },
      { nombre: 'Datos de unidad que refiere - Domicilio', campo: 'domicilio_refiere', tipo: 'string' },
      { nombre: 'Apellido Paterno a quien se refiere', campo: 'receptor_apellido_paterno', tipo: 'string' },
      { nombre: 'Apellido Materno a quien se refiere', campo: 'receptor_apellido_materno', tipo: 'string' },
      { nombre: 'Nombre a quien se refiere', campo: 'receptor_nombre', tipo: 'string' },
      { nombre: 'Resumen Clínico', campo: 'resumen_clinico', tipo: 'string' },
      //{ nombre: 'Auxiliares de diagnóstico practicados', campo: 'auxiliares_diagnostico', tipo: 'string' },
      { nombre: 'Diagnóstico(s) de envio', campo: 'diagnostico_envio', tipo: 'string' },
      { nombre: 'Nombre del Paciente, Responsable o Tutor', campo: 'nombre_responsable', tipo: 'string' },
      /*
      { nombre: 'Nombre del Testigo', campo: 'nombre_testigo', tipo: 'string' }
      */
    ],
    editedItem: {
      urgencia: null,
      domicilio: null,
      municipo_localidad: null,
      telefono: null,
      tutor_apellido_paterno: null,
      tutor_apellido_materno: null,
      tutor_nombre: null,
      unidad_refiere: null,
      servicio: null,
      estado: null,
      domicilio_refiere: null,
      receptor_apellido_paterno: null,
      receptor_apellido_materno: null,
      receptor_nombre: null,
      resumen_clinico: null,
      auxiliares_diagnosticos: [
        {
          tipo: null,
          resultado: null,
          fecha: null
        }
      ],
      diagnostico_envio: null,
      nombre_responsable: null,
      //nombre_testigo: null,
      firma_paciente: null,
      //firma_testigo: null,
    },
    defaultEditedItem: {
      urgencia: null,
      domicilio: null,
      municipo_localidad: null,
      telefono: null,
      tutor_apellido_paterno: null,
      tutor_apellido_materno: null,
      tutor_nombre: null,
      unidad_refiere: null,
      servicio: null,
      estado: null,
      domicilio_refiere: null,
      receptor_apellido_paterno: null,
      receptor_apellido_materno: null,
      receptor_nombre: null,
      resumen_clinico: null,
      auxiliares_diagnosticos: [
        {
          tipo: null,
          resultado: null,
          fecha: null
        }
      ],
      defaultAuxDiagnostico: {
        tipo: null,
        resultado: null,
        fecha: null
      },
      diagnostico_envio: null,
      nombre_responsable: null,
      //nombre_testigo: null,
      firma_paciente: null,
      //firma_testigo: null,
    },
  }),
  mounted(){
    this.getEstados();
  },
  methods: {
    nombreEstado: estado => `[${estado.codigo}] - ${estado.nombre}`,
    iniciarReferenciaMedica(){
      this.dialog = true;
    },
    getEstados(){
      this.loadingEstados = true;
      axios
        .get('/Catalogos/ListarEstados')
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstados = false;
        })
    },
    validar(dialog = true){
      var valido = true;
      
      for (let i = 0; i < this.validaciones.length; i++) {
        if(this.editedItem[this.validaciones[i].campo] == null || (this.validaciones[i].tipo == 'string' && this.editedItem[this.validaciones[i].campo].length < 0)){
          valido = false;
          if(dialog) this.$refs.alert.setMessage("", `El Campo ${this.validaciones[i].nombre} es Obligatorio`);
          if(dialog) this.$refs.alert.open();
          break;
        }

        if(this.validaciones[i].tipo == 'boolean' && this.validaciones[i].nota == true && this.editedItem[this.validaciones[i].campo] == this.validaciones[i].valor_nota && ( this.editedItem[this.validaciones[i].campo+'_nota'] == null ||  this.editedItem[this.validaciones[i].campo+'_nota'].length < 0    )){
          valido = false;
          if(dialog) this.$refs.alert.setMessage("", `La Nota del Campo ${this.validaciones[i].nombre} es Obligatoria`);
          if(dialog) this.$refs.alert.open();
          break;
        }
      }

      if(valido == false) return;
      
      if(
        (
          (this.editedItem.tutor_apellido_paterno != null && this.editedItem.tutor_apellido_paterno.length > 0) ||
          (this.editedItem.tutor_apellido_materno != null && this.editedItem.tutor_apellido_materno.length > 0) ||
          (this.editedItem.tutor_nombre != null && this.editedItem.tutor_nombre.length > 0)
        ) &&
        (
          (this.editedItem.tutor_apellido_paterno == null || this.editedItem.tutor_apellido_paterno.length == 0) ||
          (this.editedItem.tutor_apellido_materno == null || this.editedItem.tutor_apellido_materno.length == 0) ||
          (this.editedItem.tutor_nombre == null || this.editedItem.tutor_nombre.length == 0)
        )
      ){
          valido = false;
          if(dialog) this.$refs.alert.setMessage("", `El Nombre del Familiar o Tutor que autoriza esta Incompleto.`);
          if(dialog) this.$refs.alert.open();
          return false;
      }

      if(this.editedItem.auxiliares_diagnosticos.length == 0){
        valido = false;
        if(dialog) this.$refs.alert.setMessage("", "No se indicaron Auxiliares de Diagóstico Practicados");
        if(dialog) this.$refs.alert.open();
        return false;
      }

      if(this.editedItem.auxiliares_diagnosticos.length > 0){
        this.editedItem.auxiliares_diagnosticos.forEach((ad,renglon) => {
          if(ad.tipo == null || ad.tipo.length <= 0){
            valido = false;
            if(dialog) this.$refs.alert.setMessage("", `No se indicó el Tipo de Auxiliar de Diagóstico Practicado en el Renglon #${renglon+1}`);
            if(dialog) this.$refs.alert.open();
            return false;
          }


          if(ad.resultado == null || ad.resultado.length <= 0){
            valido = false;
            if(dialog) this.$refs.alert.setMessage("", `No se indicó el Resultado de Auxiliar de Diagóstico Practicado en el Renglon #${renglon+1}`);
            if(dialog) this.$refs.alert.open();
            return false;
          }

          if(ad.fecha == null || ad.fecha.length <= 0){
            valido = false;
            if(dialog) this.$refs.alert.setMessage("", `No se indicó la Fecha de Auxiliar de Diagóstico Practicado en el Renglon #${renglon+1}`);
            if(dialog) this.$refs.alert.open();
            return false;
          }
        });
      }

      if(!valido) return false;

      if(this.$refs.paciente.isEmpty() && !this.extemporaneo){
        valido = false;
        if(dialog) this.$refs.alert.setMessage("", "Falta la Firma del Paciente");
        if(dialog) this.$refs.alert.open();
        return false;
      }

      /*
      if(this.$refs.testigo.isEmpty()){
        valido = false;
        if(dialog) this.$refs.alert.setMessage("", "Falta la Firma del Testigo");
        if(dialog) this.$refs.alert.open();
        return;
      }
      */

      return valido;
    },
    finalizar(){
      if(!this.validar()) return;

      this.dialog = false;
    },
    cancelar(){
      this.restablecer();
      this.dialog = false;
    },
    obtenerDatos(){
      if(this.validar(false)){
        this.editedItem.firma_paciente = (!this.extemporaneo) ? this.$refs.paciente.save() : null;
        //this.editedItem.firma_testigo = this.$refs.testigo.save();

        return this.editedItem;
      }
      else return null;

    },
    restablecer(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.editedItem.auxiliares_diagnosticos = [];
      this.editedItem.auxiliares_diagnosticos.push(Object.assign({}, this.defaultAuxDiagnostico));
      this.$refs.paciente.clear();
      //this.$refs.testigo.clear();
    },
    agregarAuxDiagnostico(){
      this.editedItem.auxiliares_diagnosticos.push(Object.assign({}, this.defaultAuxDiagnostico))
    },
    eliminarAuxDiagnostico(item){
      this.editedItem.auxiliares_diagnosticos = this.editedItem.auxiliares_diagnosticos.filter(x => x != item);
    },
  }
}
</script>