
<template>
  <v-expansion-panels accordion>
      <v-expansion-panel
        v-for="consulta in historial"
        :key="consulta.id_paciente_consulta"
      >
        <v-expansion-panel-header>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" class="ma-0 pa-0">
              <span class="font-weight-bold">Programa:</span> {{consulta.programa}}
              <br>
              <br>
              <span class="font-weight-bold">Unidad Medica:</span> {{consulta.unidad_medica}}
              <br>
              <br>
              <span class="font-weight-bold">Proyecto:</span> {{consulta.proyecto}}
              <br>
              <br>
              <span class="font-weight-bold">Fecha y Hora:</span> {{consulta.fecha}}
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <span class="font-weight-bold">Municipio:</span> {{consulta.municipio}}
              <br>
              <br>
              <span class="font-weight-bold">Localidad Atención:</span> {{consulta.localidad_atencion}}
              <br>
              <br>
              <span class="font-weight-bold">Estado:</span> {{consulta.estado}}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <consulta
            :consulta="consulta"
            
          >
          </consulta>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
  import axios from 'axios'
  import Consulta from './Historial/Consulta.vue'

	export default {
    components: {
      Consulta
    },
		data: () => ({
      id_paciente: null,
      historial: []
		}),
    methods: {
      limpiarHistorial(){
        this.id_paciente = null;
        this.historial = [];
      },
      cambiarPaciente(id_paciente){
        this.id_paciente = id_paciente;
        axios
        .get(`/Historial/ListarHistorial/${this.id_paciente}`)
        .then(response => {
          this.historial = response.data;
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
	};
</script>